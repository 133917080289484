import React from 'react';
import Appearance from 'styles/Appearance.js';
import LottieView from 'views/Lottie.js';
import Utils from 'files/Utils.js';

export const AltBadge = ({ className, content, labelStyle, style, onClick }) => {

    const Component = ({ color, index, text }) => {
        if(!text) {
            return null;
        }
        return (
            <div
            key={index}
            className={onClick ? 'text-button':''}
            onClick={onClick}
            style={{
                alignItems: 'center',
                backgroundColor: Utils.hexToRGBA(color, 0.25),
                borderRadius: 20,
                display: 'flex',
                flex: 'none',
                marginRight: 8,
                maxWidth: '100%',
                overflow: 'hidden',
                padding: '4px 10px 4px 10px',
                position: 'relative',
                textAlign: 'center',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                ...style
            }}>
                <span
                className={className}
                style={{
                    color: color,
                    display: 'block',
                    fontSize: 14,
                    fontWeight: 800,
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    width: '100%',
                    ...labelStyle,
                    ...window.theme === 'dark' && {
                        color: 'white'
                    }
                }}>{text.toString().toUpperCase()}</span>
            </div>
        )
    }

    return Array.isArray(content) ?
        content.map((props, index) => {
            let { text, color } = props || {};
            if(!text || !color) {
                return;
            }
            return (
                <Component
                key={index}
                {...props} />
            )
        })
        :
        content && content.text && content.color
            ?
            <Component
            {...content} />
            :
            null
}

const getEntryBorderRadius = props => {
    if(props.bottomBorder === false) {
        return null;
    }
    if(props.singleItem) {
        return {
            borderRadius: 7
        };
    }
    if(props.lastItem) {
        return {
            borderBottomLeftRadius: 7,
            borderBottomRightRadius: 7
        }
    }
    if(props.firstItem) {
        return {
            borderTopLeftRadius: 7,
            borderTopRightRadius: 7,
            borderBottom: `1px solid ${Appearance.colors.divider()}`
        }
    }
    return {
        borderBottom: `1px solid ${Appearance.colors.divider()}`
    }
}

const Views = {
    entry: props => {
        return (
            <div
            key={props.key}
            onClick={props.onClick}
            style={{
                ...getEntryBorderRadius(props),
                overflow: 'hidden'
            }}>
                <div className={`${props.onClick ? `view-entry ${window.theme}` : ''}`}
                style={{
                    padding: '18px 20px 18px 20px',
                    ...props.style
                }}>
                    <div
                    className={props.className || ''}
                    style={{
                        display: 'flex',
                        flex: 1,
                        flexDirection: 'row',
                        width: '100%',
                        alignItems: 'center',
                        position: 'relative'
                    }}>
                        {typeof(props.icon) === 'function'
                            ?
                            props.icon()
                            :
                            props.hideIcon !== true
                                ?
                                <div style={{
                                    position: 'relative',
                                    width: 30,
                                    height: 30,
                                    minWidth: 30,
                                    minHeight: 30,
                                    ...(props.icon ? props.icon.style : null)
                                }}>
                                    {props.loading
                                        ?
                                        <div style={{
                                            ...Appearance.icons.standard(),
                                            minWidth: Appearance.icons.standard().width,
                                            minHeight: Appearance.icons.standard().height,
                                            width: '100%',
                                            height: '100%',
                                            display: 'flex',
                                            borderRadius: '50%',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            ...props.icon && props.icon.imageStyle
                                        }}>
                                            <LottieView
                                            loop={true}
                                            autoPlay={true}
                                            source={require('files/lottie/dots-white.json')}
                                            style={{
                                                width: 15,
                                                height: 15,
                                                paddingBottom: 2
                                            }}/>
                                        </div>
                                        :
                                        <img
                                        src={props.icon && props.icon.path ? props.icon.path : 'images/blue-loader-dot.png'}
                                        className={props.icon && props.icon.onClick ? 'text-button' : ''}
                                        onClick={props.icon ? props.icon.onClick : null}
                                        style={{
                                            ...Appearance.icons.standard(),
                                            minWidth: Appearance.icons.standard().width,
                                            minHeight: Appearance.icons.standard().height,
                                            ...props.icon && props.icon.imageStyle
                                        }}/>
                                    }
                                    {props.icon && props.icon.badge && props.icon.badge.text && (
                                        <div style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            textAlign: 'center',
                                            position: 'absolute',
                                            top: -2,
                                            right: -6,
                                            width: 15,
                                            height: 15,
                                            borderRadius: '50%',
                                            padding: 4,
                                            overflow: 'hidden',
                                            backgroundColor: props.icon.badge.color || Appearance.colors.primary()
                                        }}>
                                            <span style={{
                                                color: 'white',
                                                fontSize: 9,
                                                fontWeight: '600'
                                            }}>{props.icon.badge.text}</span>
                                        </div>
                                    )}
                                    {props.icon && props.icon.unread && props.icon.unread.value && (
                                        <div
                                        className={typeof(props.icon.unread.onClick) === 'function' ? 'text-button' : ''}
                                        onClick={typeof(props.icon.unread.onClick) === 'function' ? e => {
                                            e.stopPropagation();
                                            props.icon.unread.onClick();
                                        } : null}
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            textAlign: 'center',
                                            position: 'absolute',
                                            top: -2,
                                            right: -6,
                                            width: 15,
                                            height: 15,
                                            borderRadius: '50%',
                                            overflow: 'hidden',
                                            backgroundColor: Appearance.colors.primary()
                                        }}>
                                            <div style={{
                                                width: 5,
                                                height: 5,
                                                backgroundColor: 'white',
                                                borderRadius: '50%',
                                                overflow: 'hidden'
                                            }} />
                                        </div>
                                    )}
                                </div>
                                :
                                null
                        }
                        <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            flexShrink: 1,
                            width: '100%',
                            minWidth: 0,
                            paddingLeft: props.hideIcon === true ? 0 : 12
                        }}>
                            {props.badge && (Array.isArray(props.badge) || props.badge.color)
                                ?
                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    minWidth: 0
                                }}>
                                    <span style={{
                                        ...Appearance.textStyles.title(),
                                        ...(props.textStyles ? props.textStyles.title : null),
                                        flexShrink: 1,
                                        paddingRight: 8,
                                        ...(props.style ? props.style.title : null)
                                    }}>{props.title}</span>
                                    <AltBadge content={props.badge} />
                                </div>
                                :
                                <span style={{
                                    ...Appearance.textStyles.title(),
                                    flexGrow: 1
                                }}>{props.title}</span>
                            }
                            <span style={{
                                ...Appearance.textStyles.subTitle(),
                                ...(props.textStyles ? props.textStyles.subTitle : null),
                                ...(props.style ? props.style.subTitle : null)
                            }}>{props.subTitle}</span>
                            {props.supportingTitle
                                ?
                                <span style={{
                                    ...Appearance.textStyles.subTitle(),
                                    ...(props.textStyles ? props.textStyles.supportingTitle : null),
                                    ...(props.style ? props.style.supportingTitle : null)
                                }}>{props.supportingTitle}</span>
                                :
                                null
                            }
                        </div>
                        {props.rightContent || props.onClick && (
                            <div style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center'
                            }}>
                                {props.rightContent}
                                {props.onClick && (
                                    <img
                                    src={'images/next-arrow-grey-small.png'}
                                    style={{
                                        width: 12,
                                        height: 12,
                                        objectFit: 'contain',
                                        marginLeft: 8,
                                        opacity: 0.75
                                    }} />
                                )}
                            </div>
                        )}
                    </div>
                </div>
                {props.bottomContent}
            </div>
        )
    },
    row: props => {
        return (
            <div
            key={props.index}
            style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                padding: '8px 12px 8px 12px',
                borderBottom: props.bottomBorder ? `1px solid ${Appearance.colors.divider()}` : null
            }}>
                <span style={{
                    ...Appearance.textStyles.key(),
                    flexShrink: 1
                }}>{props.label}</span>
                <span style={{
                    ...Appearance.textStyles.value(),
                    flexGrow: 1
                }}>{props.value}</span>
            </div>
        )
    }
}
export default Views;
