import moment from 'moment-timezone';

import { PhoneNumberFormat, PhoneNumberUtil } from 'google-libphonenumber';
import Request from 'files/Request.js';

const Utils = {
    addressLookup: async (utils, text, props) => {
        return new Promise(async (resolve, reject) => {
            try {
                let { places } = await Request.get(utils, '/resources/', {
                    search_text: text,
                    type: 'address_lookup',
                    use_autocomplete: true,
                    ...props
                })
                resolve({
                    results: places.map(result => {
                        return {
                            ...result,
                            ...result.location && {
                                location: {
                                    latitude: result.location.lat,
                                    longitude: result.location.long
                                }
                            }
                        }
                    })
                })

            } catch(e) {
                reject(e);
            }
        })
    },
    adjustColor: (color, percent) => {
        var R = parseInt(color.substring(1,3),16);
        var G = parseInt(color.substring(3,5),16);
        var B = parseInt(color.substring(5,7),16);

        R = parseInt(R * (100 + percent) / 100);
        G = parseInt(G * (100 + percent) / 100);
        B = parseInt(B * (100 + percent) / 100);

        R = (R<255)?R:255;
        G = (G<255)?G:255;
        B = (B<255)?B:255;

        var RR = ((R.toString(16).length==1)?"0"+R.toString(16):R.toString(16));
        var GG = ((G.toString(16).length==1)?"0"+G.toString(16):G.toString(16));
        var BB = ((B.toString(16).length==1)?"0"+B.toString(16):B.toString(16));

        return "#"+RR+GG+BB;
    },
    attributeForKey: {
        select: (e, key) => {
            let optionElement = e.target.childNodes[e.target.selectedIndex];
            return optionElement.getAttribute(key);
        }
    },
    format: {
        address: (props = {}) => {

            // return formatted address in accordance with google auto complete formatting
            let { administrative_area_level_1, country, locality, postal_code, street_address_1, street_address_2 } = props || {};
            if(administrative_area_level_1 && country && locality && postal_code && street_address_1) {
                return `${street_address_1}${street_address_2 ? `, ${street_address_2 }` : ''}. ${locality}, ${administrative_area_level_1} ${postal_code}, ${country}`;
            }
            // return ala-carte address components as they are found
            return [ street_address_1, street_address_2, locality, administrative_area_level_1, postal_code, country ].filter(val => {
                return val && val.toString().length > 1 ? true : false;
            }).join(', ');
        },
        date: (date, withoutTime = false) => {
            if(!date ) {
                return 'Unknown';
            }
            let next_date = moment(date);
            if(next_date.isValid() !== true) {
                return 'Date is not valid';
            }
            if(moment().isSame(next_date, 'day')) {
                return moment(date).format(withoutTime ? '[Today] MMMM Do' : '[Today at] h:mma');
            }
            if(moment().subtract(1, 'days').isSame(next_date, 'day')) {
                return moment(date).format(withoutTime ? '[Yesterday] MMMM Do' : '[Yesterday at] h:mma');
            }
            if(next_date > moment() && next_date <= moment().add(6, 'days')) {
                return moment(date).format(withoutTime ? 'dddd MMMM Do' : 'dddd [at] h:mma');
            }
            if(moment().isSame(next_date, 'year')) {
                return moment(date).format(withoutTime ? 'MMMM Do' : 'MMM Do [at] h:mma');
            }
            return moment(date).format('MM/DD/YYYY');
        },
        phoneNumber: (phoneNumber, countryCode = 'US') => {
            try {
                let instance = PhoneNumberUtil.getInstance();
                let result = instance.parse(phoneNumber, countryCode);
                return instance.format(result, PhoneNumberFormat.INTERNATIONAL);
            } catch(e) {
                return phoneNumber;
            }
        },
    },
    geocode: async (utils, location) => {
        return new Promise(async (resolve, reject) => {
            try {
                let { result } = await Request.get(utils, '/resources/', {
                    type: 'geocode_location',
                    ...location
                })
                resolve({
                    ...result,
                    ...result.location && {
                        location: {
                            latitude: result.location.lat,
                            longitude: result.location.long
                        }
                    }
                });

            } catch(e) {
                reject(e);
            }
        })
    },
    hexToRGBA: (hex, alpha) => {

        var c;
    	if(/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    		c= hex.substring(1).split('');
    		if(c.length === 3){
    			c= [c[0], c[0], c[1], c[1], c[2], c[2]];
    		}
    		c= '0x'+c.join('');
    		return 'rgba('+[(c>>16)&255, (c>>8)&255, c&255].join(',')+',' + alpha + ')';
    	}
    	return hex;
    },
    isMobile: () => {
        return window.innerWidth < 576;
    },
    numberFormat: value => {
        if(value > 1000000) {
            return `${(parseFloat(value) / 1000000).toFixed(2)} M`;
        }
        if(value > 1000) {
            return `${(parseFloat(value) / 1000).toFixed(2)} K`;
        }
        return value % 1 !== 0 ? parseFloat(value).toFixed(1) : value;
    },
    oxfordImplode: (items = []) => {
        if(items.length === 0) {
            return null;
        }
        if(items.length === 1) {
            return items[0];
        }
        if(items.length === 2) {
            return `${items[0]} and ${items[1]}`;
        }
        let string = '';
        for(var i in items) {
            if(i > 0) {
                string += parseInt(i) === items.length - 1 ? ', and ' : ', ';
            }
            string += items[i];
        }
        return string;
    },
    randomString: () => {
        return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)
    },
    sleep: async seconds => {
        return new Promise(resolve => {
            setTimeout(resolve, (seconds * 1000));
        })
    },
    softNumberFormat: (val, digits = 0) => {
        return isNaN(val) ? 0 : parseFloat(val).toLocaleString('en-US', { minimumFractionDigits: digits })
    },
    toCurrency: (value, currency) => {
        return parseFloat(value || 0).toLocaleString('en-US', {
            style: 'currency',
            currency: currency || 'USD'
        })
    },
    ucFirst: text => {
        return text ? (text.charAt(0).toUpperCase() + text.substring(1)) : '';
    }
}
export default Utils;
