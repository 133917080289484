import React, { useState, useEffect } from 'react';
import Appearance from 'styles/Appearance.js';

const FilePickerField = ({ fileTypes, multiple = false, onChange, style, value,  }) => {

    const [files, setFiles] = useState([]);
    const [label, setLabel] = useState('No file selected...');
    const reader = new FileReader();

    const getFileTypes = () => {

        // allow all file types if no explicit file types were requested
        if(!fileTypes || !Array.isArray(fileTypes)) {
            return '*';
        }

        // loop through file types and prepare list of accepted file types
        let types = [];
        fileTypes.forEach(type => {
            switch(type) {
                case 'txt':
                types = types.concat([ 'text/plain', 'public.plain-text' ]);
                break;

                case 'doc':
                case 'docx':
                types = types.concat([ 'application/msword', 'com.microsoft.word.doc', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ]);
                break;

                case 'mov':
                types.push('video/mov');
                break;

                case 'mp4':
                types.push('video/mp4');
                break;

                case 'm4v':
                types.push('video/m4v');
                break;

                case 'pdf':
                types = types.concat([ 'application/pdf', 'com.adobe.pdf' ]);
                break;

                case 'xls':
                case 'xlsx':
                types = types.concat([
                    'application/vnd.ms-excel',
                    'com.microsoft.excel.xls',
                    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                ]);

                case 'zip':
                types = ['application/zip'];
                break;
            }
        })

        return types.join(', ');
    }

    const onFileChange = async ({ target }) => {

        // prevent moving forward if no files were selected
        if(target.files.length === 0) {
            return;
        }

        // loop through files and fetch contents
        let files = [];
        for(const file of target.files) {
            let result = await onReadFile(file);
            files.push(result);
        }

        // update state with selected files
        setFiles(prev => {
            if(multiple === false) {
                return files;
            }
            return prev ? prev.concat(files) : files;
        });

        // notify subscribers of files change
        if(typeof(onChange) === 'function') {
            onChange(multiple ? files : files[0]);
        }
    }

    const onReadFile = async file => {
        return new Promise(resolve => {
            reader.onload = data => {
                resolve({
                    data: data.target.result,
                    file_name: file.name,
                    file_type: file.type,
                    raw: file
                });
            };
            reader.readAsDataURL(file);
        });
    }

    useEffect(() => {
        setLabel(files.length > 0 ? (multiple ? `${files.length} selected` : files[0].file_name) : `No ${multiple ? 'files' : 'file'} selected...`);
    }, [files]);

    useEffect(() => {
        if(!value) {
            setFiles([]);
            return;
        }
        setFiles(multiple ? value : [value]);
    }, [value]);

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            ...style
        }}>
            <div style={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'row',
                width: '100%'
            }}>
                <div
                className={`custom-file ${window.theme}`}
                style={{
                    flexGrow: 1,
                    overflow: 'hidden'
                }}>
                    <input
                    accept={getFileTypes()}
                    className={`custom-file-input ${window.theme}`}
                    multiple={multiple}
                    onChange={onFileChange}
                    type={'file'}/>
                    <label
                    className={`custom-file-label ${window.theme}`}
                    style={{
                        color: files.length > 0 ? Appearance.colors.text() : Appearance.colors.subText(),
                        fontSize: 12,
                        fontWeight: 500
                    }}>{label}</label>
                </div>
            </div>
        </div>
    )
}

export default FilePickerField;

export const getFileIconPath = type => {
    switch(type) {

        case 'application/msword':
        case 'com.microsoft.word.doc':
        case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        return window.theme === 'dark' ? 'images/doc-icon-white.png' : 'images/doc-icon-grey.png';

        case 'application/pdf':
        case 'com.adobe.pdf':
        return window.theme === 'dark' ? 'images/pdf-icon-white.png' : 'images/pdf-icon-grey.png';

        case 'video/mp4':
        case 'video/m4v':
        case 'video/mov':
        return window.theme === 'dark' ? 'images/general-icon-white.png' : 'images/general-icon-grey.png';

        case 'application/vnd.ms-excel':
        case 'com.microsoft.excel.xls':
        case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
        return window.theme === 'dark' ? 'images/xls-icon-white.png' : 'images/xls-icon-grey.png';

        default:
        return window.theme === 'dark' ? 'images/general-icon-white.png' : 'images/general-icon-grey.png';
    }
}
