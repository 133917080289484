export const api_version = 1;
export const app_version = '1.0.0';
export const build = '2024.11.22.14.10';
export const dev_env = false;

export const server = dev_env ? 'http://duralabs.local.192.168.4.40.nip.io' : 'https://duralabs.io';

export default {
    app_version: app_version,
    build: build,
    dev_env: dev_env,
    version: api_version,
    server: server
}
