import React, { useEffect, useRef } from 'react';
import lottie from 'lottie-web';

const LottieView = React.forwardRef(({ autoPlay, className, loop = true, reverseLoop = false, source, style }, ref) => {

    const animation = useRef(null);
    const container = useRef(null);
    const direction = useRef(null);

    const onAnimationComplete = evt => {

        // set first direction if applicable
        if(!direction.current) {
            direction.current = evt.direction;
        }

        // set next direction value
        let next = direction.current === 1 ? -1 : 1;
        direction.current = next;

        // update direction for animation and play
        animation.current.setDirection(next);
        animation.current.play();
    }

    const play = (start, end) => {
        if(isNaN(start) === false && isNaN(end) === false) {
            animation.current.playSegments([start, end], true);
            return;
        }
        animation.current.play();
    }

    const reset = () => {
        animation.current.goToAndStop(0, true);
    }

    const reverse = props => {
        animation.current.setDirection(-1);
        play(props);
    }

    useEffect(() => {
        if(ref && ref.current) {
            ref.current.play = play;
            ref.current.reset = reset;
            ref.current.reverse = reverse;
        }
    }, [ref]);

    useEffect(() => {
        animation.current = lottie.loadAnimation({
            container: container.current,
            renderer: 'svg',
            loop: reverseLoop ? false : loop,
            autoplay: autoPlay,
            animationData: source,
            rendererSettings: { preserveAspectRatio: 'xMidYMid slice' }
        });
        if(reverseLoop) {
            animation.current.addEventListener('complete', onAnimationComplete);
            return () => {
                animation.current.removeEventListener('complete', onAnimationComplete);
            }
        }
    }, [])

    return (
        <div
        ref={ref}
        className={className}
        style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
            width: '100%',
            ...style
        }}>
            <div ref={container} />
        </div>
    )
})
export default LottieView;
