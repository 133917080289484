import AddressLookupField from 'views/AddressLookupField.js';
import Appearance from 'styles/Appearance.js';
import DatePickerField from 'views/DatePickerField.js';
import FilePickerField from 'views/FilePickerField.js';
import ImagePickerField from 'views/ImagePickerField.js';
import { LayerItem } from 'structure/Layer.js';
import ListField from 'views/ListField.js';
import MultipleListField from 'views/MultipleListField.js';
import TextField from 'views/TextField.js';

export const validateRequiredFields = async get => {
    return new Promise((resolve, reject) => {
        let items = get().reduce((array, field) => {
            return array.concat(field.items);
        }, []);
        let required = items.find(item => {
            if(item.required === false) {
                return false;
            }
            return item.value === null || item.value === undefined;
        });
        if(required) {
            let error = new Error(`One or more required fields are incomplete. Please fill out the "${required.title.toLowerCase()}" before moving on`);
            reject(error);
            return;
        }
        resolve();
    })
}

const AltFieldMapper = ({ fields, utils }) => {

    const getComponent = item => {

        let { component, icon, items, loading, onChange, onValidate, props = {}, value } = item;
        switch(component) {

            case 'address_lookup':
            return (
                <AddressLookupField
                {...item}
                geocode={true}
                onChange={place => {
                    console.log(place);
                    if(typeof(onChange) === 'function') {
                        onChange({
                            address: place && {
                                administrative_area_level_1: place.administrative_area_level_1,
                                country: place.country,
                                id: place.place_id,
                                locality: place.locality,
                                name: place.name,
                                postal_code: place.postal_code,
                                street_address_1: place.street_address_1,
                                street_address_2: place.street_address_2
                            },
                            location: place && place.location
                        });
                    }
                }}
                utils={utils}
                value={value}
                {...props} />
            )

            case 'bool_list':
            return (
                <ListField
                items={[{
                    id: 'yes',
                    title: 'Yes'
                },{
                    id: 'no',
                    title: 'No'
                }]}
                onChange={item => onChange(item ? item.id === 'yes' : null)}
                value={typeof(value) === 'boolean' ? (value ? 'Yes' : 'No') : null}
                {...props} />
            );

            case 'date_picker':
            return (
                <DatePickerField
                utils={utils}
                icon={icon}
                value={value}
                selected={value}
                onValidate={onValidate}
                onDateChange={onChange}
                {...props} />
            )

            case 'file_picker':
            return (
                <FilePickerField
                onChange={onChange}
                value={value}
                {...props} />
            )

            case 'image_picker':
            return (
                <ImagePickerField
                utils={utils}
                value={value}
                onChange={onChange}
                {...props} />
            )


            case 'list':
            return (
                <ListField
                items={items}
                onChange={onChange}
                value={value}
                {...props} />
            )

            case 'multiple_list':
            return (
                <MultipleListField
                items={items}
                onChange={onChange}
                value={value}
                {...props} />
            )

            case 'textfield':
            return (
                <TextField
                icon={icon}
                value={value}
                loading={loading}
                onValidate={onValidate}
                onChange={onChange}
                {...props} />
            )

            case 'textview':
            return (
                <TextField
                icon={icon}
                value={value}
                loading={loading}
                expandWithText={true}
                onValidate={onValidate}
                onChange={onChange}
                {...props} />
            )

            default:
            return null;
        }
    }

    return fields.filter(section => {
        return section.visible !== false
    }).map((section, index, sections) => {
        return (
            <LayerItem
            collapsed={section.collapsed !== undefined ? section.collapsed : false}
            key={index}
            lastItem={typeof(section.lastItem) === 'boolean' ? section.lastItem : index === sections.length - 1}
            rightContent={section.rightContent}
            title={section.title}>
                <div style={{
                    ...Appearance.styles.unstyledPanel(),
                    overflow: 'visible',
                    padding: 24,
                    paddingTop: 16,
                    width: '100%',
                    ...section.style
                }}>
                    {section.items && section.items.filter(item => {
                        return item.visible !== false;
                    }).map((item, index, items) => {
                        return (
                            <div
                            key={index}
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                width: '100%',
                                marginBottom: index !== items.length - 1 ? 24 : 0
                            }}>
                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center'
                                }}>
                                    {item.required !== false && (item.value === null || item.value === undefined) && (
                                        <div style={{
                                            width: 5,
                                            height: 5,
                                            borderRadius: 2.5,
                                            overflow: 'hidden',
                                            backgroundColor: Appearance.colors.red,
                                            marginRight: 8,
                                            minWidth: 5
                                        }} />
                                    )}
                                    <span style={{
                                        ...Appearance.textStyles.subHeader(),
                                        display: 'block',
                                        marginBottom: 4
                                    }}>{item.title}</span>
                                </div>
                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    width: '100%',
                                    position: 'relative'
                                }}>
                                    {getComponent(item)}
                                </div>
                            </div>
                        )
                    })}
                </div>
            </LayerItem>
        )
    })
}

export default AltFieldMapper;
