
import React, { useEffect, useRef, useState } from 'react';
import { animated, useSpring } from 'react-spring';
import Utils from 'files/Utils.js';

const AnimatedElement = ({ animateIn, animateOut, animateWithPage = false, children, config, delay = 0, persist = true, style, visible }) => {

    const action = useRef(null);
    const target = useRef(null);

    const [animations, setAnimations] = useSpring(() => ({
        config: { mass: 1, tension: 180, friction: 12, ...config },
        ...animateOut
    }));
    const [visiblePrivate, setVisiblePrivate] = useState(false);

    const onSetAnimations = () => {

        // set internal visibility flag to true if applicable
        if(action.current === 'show') {
            setVisiblePrivate(true);
        }

        // update animation values with visible based timeout
        setAnimations({
            to: async next => {

                // wait using optional delay if provided
                await Utils.sleep(delay || 0);

                // run animation
                await next(action.current === 'show' ? animateIn : animateOut);

                // set internal visibility flag to false after animation has completed if applicable
                if(visible === 'hide') {
                    setVisiblePrivate(false);
                }
            }
        });
    }

    useEffect(() => {

        // run standard animations if animateWithPage is false
        if(animateWithPage === false) {
            action.current = visible ? 'show' : 'hide';
            onSetAnimations();
            return;
        }

        // set visibility flag to show render components
        setVisiblePrivate(true);

        // set interval and wait for ref to post
        let interval = setInterval(() => {

            // continue interval if no ref has posted
            if(!target.current) {
                return;
            }

            // clear interval
            clearInterval(interval);
            
            // register observer and intersection logic
            let observer = new IntersectionObserver(entries => {
                entries.forEach(entry => {
                    if(entry.intersectionRatio > 0) {
                        action.current = 'show';
                        onSetAnimations();
                    } else if(persist === false) {
                        action.current = 'hide';
                        onSetAnimations();
                    }
                });
            }, {
                rootMargin: '0px'
            });

            // add observer to component
            observer.observe(target.current);

            return () => {
                clearInterval(interval);
                observer.disconnect();
            }
        }, 100);
        
    }, [animateWithPage, visible]);

    return visiblePrivate === true && (
        <animated.div 
        ref={target}
        style={{
            ...animations,
            position: 'relative',
            width: '100%',
            ...style
        }}>
            {children}
        </animated.div>
    )
}

export default AnimatedElement;