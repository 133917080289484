import moment from 'moment';
import Request from 'files/Request.js';

class UserClass {

    active = true;
    address = null;
    avatar = null;
    created = null;
    email_address = null;
    first_name = null;
    full_name = null;
    last_login = null;
    last_name = null;
    level = null;
    location = null;
    phone_number = null;
    token = null;
    timezone = null;
    user_id = null;
    username = null;
    
    constructor() {
        return this;
    }

    close = () => {
        this.address = this.edits.address;
        this.email_address = this.edits.email_address;
        this.first_name = this.edits.first_name;
        this.full_name = this.edits.full_name;
        this.last_name = this.edits.last_name;
        this.level = this.edits.level || this.level;
        this.location = this.edits.location;
        this.password = this.edits.password;
        this.phone_number = this.edits.phone_number;
        this.timezone = this.edits.timezone;
        this.username = this.edits.username;
    }

	create = (props = {}) => {
        this.active = props.active;
        this.address = props.address;
        this.avatar = props.avatar;
        this.created = props.created && moment.utc(props.created).local();
        this.email_address = props.email_address;
        this.first_name = props.first_name;
        this.full_name = props.full_name;
        this.last_login = props.last_login && moment.utc(props.last_login).local();
        this.last_name = props.last_name;
        this.level = props.level;
        this.location = props.location && {
            latitude: props.location.lat,
            longitude: props.location.long
        };
        this.phone_number = props.phone_number;
        this.timezone = props.timezone;
        this.token = props.token;
        this.user_id = props.user_id;
        this.username = props.username;
        return this;
	}

    open = () => {
        this.edits = {
            address: this.address,
            email_address: this.email_address,
            first_name: this.first_name,
            full_name: this.full_name,
            last_name: this.last_name,
            level: this.level,
            location: this.location,
            password: this.password,
            phone_number: this.phone_number,
            timezone: this.timezone,
            username: this.username
        }
        return this.edits;
    }

    set = props => {
        this.edits = {
            ...this.edits,
            ...props
        }
        this.edits.full_name = `${this.edits.first_name} ${this.edits.last_name}`;
        return this.edits;
    }

    submit = async (utils, props) => {
        return new Promise(async (resolve, reject) => {
            try {

                // submit request to server
                let { user_id } = await Request.post(utils, '/users/', {
                    type: 'new',
                    ...this.toJSON(this.edits),
                    ...props
                });

                // end editing and set user id
                this.close();
                this.user_id = user_id;

                // notify subscribers that data has changed
                utils.content.fetch('users');

                resolve();

            } catch(e) {
                reject(e);
            }
        });
    }

    toJSON = props => {
        let target = props || this;
        return {
            address: target.address,
            email_address: target.email_address,
            first_name: target.first_name,
            last_name: target.last_name,
            level: target.level,
            location: target.location && {
                lat: target.location.latitude,
                long: target.location.longitude
            },
            password: target.password,
            phone_number: target.phone_number,
            timezone: target.timezone,
            user_id: this.user_id,
            username: target.username
        }
    }

    update = async (utils, props) => {
        return new Promise(async (resolve, reject) => {
            try {

                // submit request to server
                let { user_id } = await Request.post(utils, '/users/', {
                    type: 'update',
                    ...this.toJSON(this.edits),
                    ...props
                });

                // end editing and notify subscribers that data has changed
                this.close();
                utils.content.update({
                    object: this,
                    type: 'users'
                });

                resolve();

            } catch(e) {
                reject(e);
            }
        });
    }
}

export const fetchUser = async (utils, userID) => {
    return new Promise(async (resolve, reject) => {
        try {
            let { user } = await Request.get(utils, '/users/', {
                type: 'details',
                user_id: userID
            });
            resolve(new UserClass().create(user));
        } catch(e){
            reject(e);
        }
    });
}

const levels = {
    system_admin: 1,
    admin: 2,
    guest: 9999
}

const levelToText = code => {
    switch(code) {
        case levels.system_admin:
        return 'System Administrator';

        case levels.admin:
        return 'Administrator';

        case levels.guest:
        return 'Guest';

        default:
        return 'Unknown account type';
    }
}

export default {
    create: props => new UserClass().create(props),
    get: fetchUser,
    levels: {
        get: () => levels,
        toText: levelToText
    },
    new: () => new UserClass()
}
