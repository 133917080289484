import React, { useEffect, useRef, useState } from 'react';
import { getFileIconPath } from 'views/FilePickerField.js';
import update from 'immutability-helper';

import Abstract from 'classes/Abstract.js';
import Appearance from 'styles/Appearance.js';
import AltFieldMapper, { validateRequiredFields } from 'views/AltFieldMapper';
import AnimatedElement from 'views/AnimatedElement.js';
import Button from 'views/Button.js';
import Layer, { LayerItem } from 'structure/Layer';
import LottieView from 'views/Lottie.js';
import Request from 'files/Request.js';
import User from 'classes/User.js';
import Utils from 'files/Utils.js';
import { VelocityComponent } from 'velocity-react';
import Views, { AltBadge } from 'views/Main.js';

export const About = ({ utils }) => {

    const cardSize = Utils.isMobile() ? window.innerWidth / 2 : 250;

    const scrollContainer = useRef(null);
    const [animations, setAnimations] = useState({ right: 0 });

    const getContent = () => {

        // prepare list of section entries
        let sections = [{
            image: 'images/about-us-01.png',
            position: 'left',
            text: 'We offer end-to-end services, including mobile app development, user interface design, IOT and infrastructure development, external api integrations, ongoing technical support, and general consulting services.'
        },{
            image: 'images/about-us-02.png',
            position: 'right',
            text: `Our company prides itself on its client-centric approach, ensuring that every project aligns with the client's strategic goals and operational requirements, while also providing personalized support, transparent communication, and flexible solutions that adapt to evolving business needs and drive long-term success.`
        },{
            container: false,
            content: getScrollingDevelopmentComponents(),
            text: 'Leveraging the latest technologies, Dura Labs delivers high-quality, scalable software that enhances efficiency, drives innovation, and empowers businesses to seamlessly integrate automation, data analytics, and intelligent solutions into their operations, ultimately transforming how they serve customers and compete in the market.'
        },{
            image: 'images/about-us-03.png',
            position: 'left',
            text: 'With a commitment to transparency, collaboration, and quality, Dura Labs has built a strong reputation for delivering projects on time and within budget, making it a trusted partner for businesses looking to advance their digital presence.'
        }];

        return sections.map((section, index) => {
            if(section.container === false) {
                return (
                    <AnimatedElement
                    {...utils.animations.get('panel')}
                    animateWithPage={true}
                    key={index}
                    style={{
                        alignItems: 'center',
                        borderRadius: 10,
                        backgroundColor: Appearance.colors.panelBackground(),
                        display: 'flex',
                        flexDirection: 'column',
                        marginBottom: Appearance.styles.panelSpacing(),
                        maxWidth: 1250,
                        overflow: 'hidden',
                        width: '100%'
                    }}>
                        {section.content}
                        <div style={{
                            padding: Appearance.styles.panelPadding(),
                            textAlign: 'center',
                            width: '100%'
                        }}>
                            <span style={{
                                ...Appearance.textStyles.bodyText(),
                                display: 'block'
                            }}>{section.text}</span>
                        </div>
                    </AnimatedElement>
                )
            }
            if(Utils.isMobile() === true) {
                return (
                    <AnimatedElement
                    {...utils.animations.get('panel')}
                    animateWithPage={true}
                    key={index}
                    style={{
                        alignItems: 'center',
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%'
                    }}>
                        <div
                        className={'row mx-0'} 
                        style={{
                            borderRadius: 10,
                            backgroundColor: Appearance.colors.panelBackground(),
                            marginBottom: Appearance.styles.panelSpacing(),
                            minHeight: 500,
                            maxWidth: 1000,
                            overflow: 'hidden',
                            width: '100%'
                        }}>
                            <div 
                            className={'col-12 p-4'}
                            style={{
                                alignItems: 'center',
                                backgroundColor: Appearance.colors.divider(),
                                display: 'flex',
                                justifyContent: 'center'
                            }}>
                                <img
                                className={'float'}
                                src={section.image}
                                style={{
                                    height: '100%',
                                    objectFit: 'contain',
                                    width: '100%'
                                }} />
                            </div>
                            <div 
                            className={'col-12 m-0'}
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                padding: Appearance.styles.panelPadding(),
                                textAlign: 'center',
                                width: '100%'
                            }}>
                                <span style={{
                                    ...Appearance.textStyles.bodyText()
                                }}>{section.text}</span>
                            </div>
                        </div>
                    </AnimatedElement>
                )
            }
            return (
                <AnimatedElement
                {...utils.animations.get('panel')}
                animateWithPage={true}
                key={index}
                style={{
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%'
                }}>
                    <div
                    className={'row mx-0'} 
                    style={{
                        borderRadius: 10,
                        backgroundColor: Appearance.colors.panelBackground(),
                        marginBottom: Appearance.styles.panelSpacing(),
                        minHeight: 500,
                        maxWidth: 1000,
                        overflow: 'hidden',
                        width: '100%'
                    }}>
                        {section.position === 'left' && (
                            <div 
                            className={'col-12 col-md-6 col-lg-6 m-0 p-4 p-md-2'}
                            style={{
                                alignItems: 'center',
                                backgroundColor: Appearance.colors.divider(),
                                display: 'flex',
                                justifyContent: 'center'
                            }}>
                                <img
                                className={'float'}
                                src={section.image}
                                style={{
                                    height: '100%',
                                    objectFit: 'contain',
                                    width: '100%'
                                }} />
                            </div>
                        )}
                        <div 
                        className={'col-12 col-md-6 col-lg-6 m-0'}
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            padding: Appearance.styles.panelPadding(),
                            textAlign: 'center',
                            width: '100%'
                        }}>
                            <span style={{
                                ...Appearance.textStyles.bodyText()
                            }}>{section.text}</span>
                        </div>
                        {section.position === 'right' && (
                            <div 
                            className={'col-12 col-md-6 col-lg-6 m-0 p-4 p-md-2'}
                            style={{
                                alignItems: 'center',
                                backgroundColor: Appearance.colors.divider(),
                                display: 'flex',
                                justifyContent: 'center'
                            }}>
                                <img
                                className={'float'}
                                src={section.image}
                                style={{
                                    height: '100%',
                                    objectFit: 'contain',
                                    width: '100%'
                                }} />
                            </div>
                        )}
                    </div>
                </AnimatedElement>
            )
        });
    }

    const getDevelopmentItems = () => {
        return [{
            image: 'images/node-js-logo.png',
            title: 'Node JS'
        },{
            image: 'images/php-logo.png',
            title: 'PHP'
        },{
            image: 'images/javascript-logo.png',
            title: 'Javascript'
        },{
            image: 'images/react-logo.png',
            title: 'React JS'
        },{
            image: 'images/react-native-logo.png',
            title: 'React Native'
        },{
            image: 'images/ios-logo.png',
            title: 'iOS'
        },{
            image: 'images/android-logo.png',
            title: 'Android'
        },{
            image: 'images/mongodb-logo.png',
            title: 'Mongo DB'
        },{
            image: 'images/mysql-logo.png',
            title: 'MySQL'
        },{
            image: 'images/aws-logo.png',
            title: 'Amazon Web Services'
        },{
            image: 'images/linux-logo.png',
            title: 'Linux'
        }];
    }

    const onAnimationComplete = () => {
        if(animations.right === 0) {
            runScrollContainerAnimations();
            return;
        }
        setAnimations({ right: 0 });
    }

    const getScrollingDevelopmentComponents = () => {
    
        // prepare list of development language and frameworks
        let items = getDevelopmentItems();

        // return a horizontally scrolling list 
        return (
            <div 
            ref={scrollContainer}
            style={{
                marginTop: Utils.isMobile() ? 24 : '2vw',
                maxWidth: '100%',
                overflow: 'hidden',
                width: '100%'
            }}>
                <VelocityComponent
                animation={animations}
                duration={30000}
                complete={onAnimationComplete}>
                    <div style={{
                        alignItems: 'center',
                        display: 'flex',
                        flexDirection: 'row',
                        position: 'relative'
                    }}>
                        {items.map((item, index) => {
                            return (
                                <div
                                key={index}
                                style={{
                                    ...Appearance.styles.unstyledPanel(),
                                    alignItems: 'center',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    height: cardSize,
                                    justifyContent: 'center',
                                    marginLeft: 8,
                                    marginRight: 8,
                                    minWidth: cardSize
                                }}>
                                    <img
                                    src={item.image}
                                    style={{
                                        borderRadius: 5,
                                        height: 100,
                                        marginBottom: 12,
                                        objectFit: 'contain',
                                        width: 100
                                    }} />
                                    <span style={{
                                        ...Appearance.textStyles.title()
                                    }}>{item.title}</span>
                                </div>
                            )
                        })}
                    </div>
                </VelocityComponent>
            </div>
        )
    }

    const runScrollContainerAnimations = () => {
        let interval = setInterval(() => {

            // prevent moving forward if a ref has not posted
            if(!scrollContainer.current) {
                return;
            }

            // clear interval
            clearInterval(interval);

            // determine amount of items that can fit on screen at once
            let count = scrollContainer.current.clientWidth / cardSize;

            // get total item count and animate container scroll
            let items = getDevelopmentItems();
            setAnimations({ right: (items.length - count) * (cardSize + 32) });

        }, 100);
    }

    useEffect(() => {
        runScrollContainerAnimations();
    }, [scrollContainer.current]);

    return (
        <div style={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            padding: '5vw',
            width: '100%'
        }}>
            <AnimatedElement
            {...utils.animations.get('panel')}
            animateWithPage={true}
            style={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column',
                width: '100%'
            }}>
                <div 
                className={'text-left text-md-center'}
                style={{
                    alignItems: 'center',
                    borderRadius: 10,
                    backgroundColor: Appearance.colors.panelBackground(),
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    marginBottom: Appearance.styles.panelSpacing(),
                    maxWidth: 1250,
                    padding: Appearance.styles.panelPadding(),
                    width: '100%'
                }}>
                    <span style={{
                        color: Appearance.colors.text(),
                        fontSize: Appearance.textStyles.headerFontSize(),
                        fontWeight: 900,
                        lineHeight: 1.2,
                        marginBottom: 16,
                        width: '100%'
                    }}>{'ABOUT US'}</span>
                    <span style={{
                        ...Appearance.textStyles.bodyText()
                    }}>{'At Dura Labs, we\'re not just about software; we\'re about crafting digital experiences that transform the way businesses operate. Our passion lies in developing custom software solutions that are as unique as the challenges our clients face. We believe that your success is our success, and we\'re here to turn your bold ideas into reality.'}</span>
                </div>
            </AnimatedElement>
            {getContent()}
            <StartConverstation 
            style={{
                marginTop: Appearance.styles.panelSpacing()
            }} 
            utils={utils} />
        </div>
    )
}

export const Account = ({ utils }) => {

    return (
        <div style={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            padding: '5vw',
            width: '100%'
        }}>
            <AnimatedElement
            {...utils.animations.get('panel')}
            animateWithPage={true}
            style={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column',
                width: '100%'
            }}>
                <div 
                className={'text-left text-md-center'}
                style={{
                    alignItems: 'center',
                    borderRadius: 10,
                    backgroundColor: Appearance.colors.panelBackground(),
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    marginBottom: Appearance.styles.panelSpacing(),
                    maxWidth: 1250,
                    padding: Appearance.styles.panelPadding(),
                    width: '100%'
                }}>
                    <span style={{
                        color: Appearance.colors.text(),
                        fontSize: Appearance.textStyles.headerFontSize(),
                        fontWeight: 900,
                        lineHeight: 1.2,
                        marginBottom: 16,
                        width: '100%'
                    }}>{'ACCOUNT'}</span>
                    <span style={{
                        ...Appearance.textStyles.bodyText()
                    }}>{'At Dura Labs, we\'re not just about software; we\'re about crafting digital experiences that transform the way businesses operate. Our passion lies in developing custom software solutions that are as unique as the challenges our clients face. We believe that your success is our success, and we\'re here to turn your bold ideas into reality.'}</span>
                </div>
            </AnimatedElement>
        </div>
    )
}

export const Contact = ({ utils }) => {

    const [edits, setEdits] = useState({});
    const [loading, setLoading] = useState(false);

    const onUpdateTarget = props => {
        setEdits(edits => ({ ...edits, ...props }));
    }

    const onSubmitClick = async() => {
        try {

            // verify that required fields were filled out
            await validateRequiredFields(getFields);

            // set loading flag and submit request to server
            setLoading('submit');
            await Request.post(utils, '/utils/', {
                ...edits,
                start_date: edits.start_date && edits.start_date.format('YYYY-MM-DD'),
                services: edits.services && edits.services.map(service => service.id),
                type: 'new_website_contact_form'
            });

            // end loading and show confirmation alert
            setLoading(false);
            utils.alert.show({
                title: 'All Done!',
                message: 'We have received your contact request and will reach out to you shortly.',
                onClick: setEdits.bind(this, {})
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue submitting your contact request. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const getFields = () => {
        return [{
            collapsed: null,
            key: 'details',
            items: [{
                component: 'textfield',
                key: 'first_name',
                onChange: text => onUpdateTarget({ first_name: text }),
                title: 'First Name',
                value: edits.first_name
            },{
                component: 'textfield',
                key: 'last_name',
                onChange: text => onUpdateTarget({ last_name: text }),
                title: 'Last Name',
                value: edits.last_name
            },{
                component: 'textfield',
                key: 'email_address',
                onChange: text => onUpdateTarget({ email_address: text }),
                title: 'Email Address',
                value: edits.email_address
            },{
                component: 'textfield',
                key: 'phone_number',
                onChange: text => onUpdateTarget({ phone_number: text }),
                props: {
                    format: 'phone_number'
                },
                title: 'Phone Number',
                value: edits.phone_number
            },{
                component: 'textfield',
                key: 'company',
                onChange: text => onUpdateTarget({ company: text }),
                required: false,
                title: 'Company',
                value: edits.company
            },{
                component: 'multiple_list',
                items: getProjectServicesListItems(),
                key: 'services',
                onChange: items => onUpdateTarget({ services: items && items.length > 0 ? items : null }),
                title: 'Which services are you interested in?',
                value: edits.services
            },{
                component: 'date_picker',
                key: 'start_date',
                onChange: text => onUpdateTarget({ start_date: text }),
                props: {
                    fieldStyle: {
                        textAlign: 'left'
                    },
                    placeholder: 'Click to select a date...'
                },
                title: 'When would you like to get started?',
                value: edits.start_date
            },{
                component: 'textview',
                key: 'description',
                onChange: text => onUpdateTarget({ description: text }),
                title: 'Tell us about your project',
                value: edits.description
            }]
        }];
    }

    const getProjectServicesListItems = () => {
        return [{
            id: 'android_app_development',
            title: 'Android App Development'
        },{
            id: 'consulting',
            title: 'Consulting'
        },{
            id: 'database_management',
            title: 'Database Management'
        },{
            id: 'hybrid_app_development',
            title: 'Hybrid App Development'
        },{
            id: 'ios_app_development',
            title: 'iOS App Development'
        },{
            id: 'other',
            title: 'Other'
        },{
            id: 'web_design',
            title: 'Responsive Web Design'
        },{
            id: 'server_admin',
            title: 'Server Administration'
        }];
    }

    return (
        <div style={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            padding: '5vw',
            width: '100%'
        }}>
            <AnimatedElement
            {...utils.animations.get('panel')}
            visible={true}
            style={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column',
                width: '100%'
            }}>
                <div 
                className={'text-left text-md-center'}
                style={{
                    borderRadius: 10,
                    backgroundColor: Appearance.colors.panelBackground(),
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    marginBottom: Appearance.styles.panelSpacing(),
                    maxWidth: 1250,
                    padding: Appearance.styles.panelPadding(),
                    width: '100%'
                }}>
                    <span style={{
                        color: Appearance.colors.text(),
                        fontSize: Appearance.textStyles.headerFontSize(),
                        fontWeight: 900,
                        lineHeight: 1.2,
                        marginBottom: 16
                    }}>{'CONTACT US'}</span>
                    <span style={{
                        ...Appearance.textStyles.bodyText()
                    }}>{'We appreciate your interest in our products and services. To ensure we connect you with the right member of our sales team, please take a moment to fill out our contact form. This will enable our sales team to understand your requirements and respond promptly with tailored solutions. We appreciate your time and look forward to assisting you.'}</span>
                </div>
            </AnimatedElement>
            <AnimatedElement
            {...utils.animations.get('panel')}
            visible={true}
            style={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column',
                width: '100%'
            }}>
                <div 
                className={'text-left text-md-center'}
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    maxWidth: 1000,
                    width: '100%'
                }}>
                    <AltFieldMapper
                    fields={getFields()}
                    utils={utils} />
                </div>
                <div 
                className={'text-left text-md-center'}
                style={{
                    borderRadius: 10,
                    backgroundColor: Appearance.colors.panelBackground(),
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    maxWidth: 1000,
                    padding: Appearance.styles.panelPadding(),
                    width: '100%'
                }}>
                    <span style={{
                        color: Appearance.colors.subText(),
                        fontSize: 12,
                        fontWeight: 500,
                        lineHeight: 1.2,
                        marginBottom: 24,
                        textAlign: 'left',
                        width: '100%'
                    }}>{`By submitting your information to our service, you acknowledge that your data will be used to provide you with updates and promotional materials related to our products and services. We are committed to protecting your privacy and ensuring your information is handled securely. Please review our privacy policy for more details on how we collect, use, and protect your information.`}</span>
                    <Button 
                    color={'primary'} 
                    label={'Submit'}
                    loading={loading === 'submit'}
                    onClick={onSubmitClick}
                    type={'large'} />
                </div>
            </AnimatedElement>
        </div>
    )
}

export const DuraCode = ({ utils }) => {

    const onSignupClick = () => {
        utils.layer.open({
            abstract: Abstract.create({
                object: User.new(),
                type: 'users'
            }),
            Component: NewUser.bind(this, { channel: 'duracode' }),
            id: 'new_user'
        });
    }

    const getContent = () => {

        // prepare list of section entries
        let sections = [{
            image: 'images/duracode-image-01.png',
            position: 'left',
            text: 'High-quality code is essential for building reliable, maintainable, and scalable software. It not only ensures that a program functions correctly and efficiently but also makes it easier to understand, modify, and extend over time. Well-written code reduces the likelihood of bugs and technical debt, saving time and resources in the long run by preventing costly rework or patches.'
        },{
            image: 'images/duracode-image-02.png',
            position: 'right',
            text: `Working one-to-one with our service can save you a significant amount of time by helping you avoid common pitfalls and speeding up your learning process. Rather than spending hours or days struggling to solve a problem, we can provide targeted guidance, helping you identify mistakes quickly and teaching you best practices that improve your efficiency.`
        },{
            image: 'images/duracode-image-03.png',
            position: 'left',
            text: 'Protecting your code with a Non-Disclosure Agreement (NDA) is a crucial step in safeguarding your intellectual property and ensuring that your ideas remain confidential. The NDA between you and DuraCode prevents our team from disclosing, using, or distributing your work without your permission.'
        }];

        return sections.map((section, index) => {
            if(Utils.isMobile() === true) {
                return (
                    <AnimatedElement
                    {...utils.animations.get('panel')}
                    animateWithPage={true}
                    key={index}
                    style={{
                        alignItems: 'center',
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%'
                    }}>
                        <div
                        className={'row mx-0'} 
                        style={{
                            borderRadius: 10,
                            backgroundColor: Appearance.colors.panelBackground(),
                            marginBottom: Appearance.styles.panelSpacing(),
                            minHeight: 500,
                            maxWidth: 1000,
                            overflow: 'hidden',
                            width: '100%'
                        }}>
                            <div 
                            className={'col-12 p-4'}
                            style={{
                                alignItems: 'center',
                                backgroundColor: Appearance.colors.divider(),
                                display: 'flex',
                                justifyContent: 'center'
                            }}>
                                <img
                                className={'float'}
                                src={section.image}
                                style={{
                                    height: '100%',
                                    objectFit: 'contain',
                                    width: '100%'
                                }} />
                            </div>
                            <div 
                            className={'col-12 m-0'}
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                padding: Appearance.styles.panelPadding(),
                                textAlign: 'center',
                                width: '100%'
                            }}>
                                <span style={{
                                    ...Appearance.textStyles.bodyText()
                                }}>{section.text}</span>
                            </div>
                        </div>
                    </AnimatedElement>
                )
            }
            return (
                <AnimatedElement
                {...utils.animations.get('panel')}
                animateWithPage={true}
                key={index}
                style={{
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%'
                }}>
                    <div
                    className={'row mx-0'} 
                    style={{
                        borderRadius: 10,
                        backgroundColor: Appearance.colors.panelBackground(),
                        marginBottom: Appearance.styles.panelSpacing(),
                        minHeight: 500,
                        maxWidth: 1000,
                        overflow: 'hidden',
                        width: '100%'
                    }}>
                        {section.position === 'left' && (
                            <div 
                            className={'col-12 col-md-6 col-lg-6 m-0 p-4 p-md-2'}
                            style={{
                                alignItems: 'center',
                                backgroundColor: Appearance.colors.divider(),
                                display: 'flex',
                                justifyContent: 'center'
                            }}>
                                <img
                                className={'float'}
                                src={section.image}
                                style={{
                                    height: '100%',
                                    objectFit: 'contain',
                                    width: '100%'
                                }} />
                            </div>
                        )}
                        <div 
                        className={'col-12 col-md-6 col-lg-6 m-0'}
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            padding: Appearance.styles.panelPadding(),
                            textAlign: 'center',
                            width: '100%'
                        }}>
                            <span style={{
                                ...Appearance.textStyles.bodyText()
                            }}>{section.text}</span>
                        </div>
                        {section.position === 'right' && (
                            <div 
                            className={'col-12 col-md-6 col-lg-6 m-0 p-4 p-md-2'}
                            style={{
                                alignItems: 'center',
                                backgroundColor: Appearance.colors.divider(),
                                display: 'flex',
                                justifyContent: 'center'
                            }}>
                                <img
                                className={'float'}
                                src={section.image}
                                style={{
                                    height: '100%',
                                    objectFit: 'contain',
                                    width: '100%'
                                }} />
                            </div>
                        )}
                    </div>
                </AnimatedElement>
            )
        });
    }

    return (
        <div style={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            padding: '5vw',
            width: '100%'
        }}>
            <AnimatedElement
            {...utils.animations.get('panel')}
            visible={true}
            style={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column',
                width: '100%'
            }}>
                <div style={{
                    alignItems: 'center',
                    borderRadius: 10,
                    backgroundColor: Appearance.colors.panelBackground(),
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    marginBottom: Appearance.styles.panelSpacing(),
                    maxWidth: 1250,
                    padding: Appearance.styles.panelPadding(),
                    textAlign: 'center',
                    width: '100%'
                }}>
                    <img
                    src={'images/duracode-logo-header.png'}
                    style={{
                        height: 150,
                        marginBottom: Utils.isMobile() ? 24 : 36,
                        width: 'auto'
                    }} />
                    <span style={{
                        ...Appearance.textStyles.bodyText()
                    }}>{`DuraCode helps developers turn coding mistakes into learning opportunities by offering one-to-one guidance and personalized solutions. When you encounter something that you can't figure out, our service not only explains what went wrong but also provides context and reasoning behind the mistake, helping you grasp the underlying principles at play.`}</span>
                </div>
            </AnimatedElement>
            {getContent()}
            <AnimatedElement
            {...utils.animations.get('panel')}
            animateWithPage={true}
            style={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column',
                width: '100%'
            }}>
                <div style={{
                    alignItems: 'center',
                    borderRadius: 10,
                    backgroundColor: Appearance.colors.panelBackground(),
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    marginBottom: Appearance.styles.panelSpacing(),
                    maxWidth: 1250,
                    padding: Appearance.styles.panelPadding(),
                    textAlign: 'center',
                    width: '100%'
                }}>
                    <span style={{
                        color: Appearance.colors.text(),
                        fontSize: Appearance.textStyles.headerFontSize(),
                        fontWeight: 900,
                        lineHeight: 1.2,
                        marginBottom: Utils.isMobile() ? 24 : 16
                    }}>{`READY TO GET STARTED?`}</span>
                    <span 
                    className={'mb-4'}
                    style={{
                        ...Appearance.textStyles.bodyText()
                    }}>{`Investing in quality code leads to better user experiences, faster development cycles, and a more sustainable, long-term approach to software engineering. Signup for a free DuraCode account to get started.`}</span>

                    <Button
                    color={'#F7B500'}
                    containerStyle={{
                        maxWidth: 350,
                        width: '100%'
                    }}
                    label={'Signup'}
                    onClick={onSignupClick}
                    type={'large'} /> 
                </div>
            </AnimatedElement>
        </div>
    )
}

export const DuraLink = ({ utils }) => {

    const [edits, setEdits] = useState({});
    const [loading, setLoading] = useState(false);

    const onSubmitClick = async () => {
        try {

            // verify that required fields were filled out
            await validateRequiredFields(getFields);

            // set loading flag and submit request to server
            setLoading('submit');
            await Request.post(utils, '/utils/', {
                ...edits,
                review_websites: edits.review_websites && edits.review_websites.map(service => service.id),
                social_media: edits.social_media && edits.social_media.map(service => service.id),
                type: 'new_app_signup_form'
            });

            // end loading and show confirmation alert
            setLoading(false);
            utils.alert.show({
                title: 'All Done!',
                message: 'We have received your request and will keep you up to date with our product launch.',
                onClick: setEdits.bind(this, {})
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue submitting your request. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onUpdateTarget = props => {
        setEdits(edits => ({ ...edits, ...props }));
    }

    const getFields = () => {
        return [{
            collapsed: null,
            key: 'contact',
            style: {
                border: 'none'
            },
            items: [{
                component: 'textfield',
                key: 'first_name',
                onChange: text => onUpdateTarget({ first_name: text }),
                title: 'First Name',
                value: edits.first_name
            },{
                component: 'textfield',
                key: 'last_name',
                onChange: text => onUpdateTarget({ last_name: text }),
                title: 'Last Name',
                value: edits.last_name
            },{
                component: 'textfield',
                key: 'email_address',
                onChange: text => onUpdateTarget({ email_address: text }),
                title: 'Email Address',
                value: edits.email_address
            },{
                component: 'textfield',
                key: 'company',
                onChange: text => onUpdateTarget({ company: text }),
                title: 'Company',
                value: edits.company
            },{
                component: 'textfield',
                key: 'city',
                onChange: text => onUpdateTarget({ city: text }),
                title: 'City',
                value: edits.city
            },{
                component: 'textfield',
                key: 'state',
                onChange: text => onUpdateTarget({ state: text }),
                title: 'State',
                value: edits.state
            },{
                component: 'textfield',
                key: 'country',
                onChange: text => onUpdateTarget({ country: text }),
                title: 'Country',
                value: edits.country
            },{
                component: 'textfield',
                key: 'industry',
                onChange: text => onUpdateTarget({ industry: text }),
                title: 'Which industry do you work in?',
                value: edits.industry
            },{
                component: 'multiple_list',
                items: getSocialMediaListItems(),
                key: 'social_media',
                onChange: text => onUpdateTarget({ social_media: text }),
                title: 'Does your business have one or more social media profiles?',
                value: edits.social_media
            },{
                component: 'bool_list',
                key: 'customer_feedback',
                onChange: text => onUpdateTarget({ customer_feedback: text }),
                title: 'Do you work with customer feedback or reviews?',
                value: edits.customer_feedback
            },{
                component: 'multiple_list',
                items: getReviewWebsitesListItems(),
                key: 'review_websites',
                onChange: text => onUpdateTarget({ review_websites: text }),
                title: 'Which websites do you use when looking for business or product reviews?',
                value: edits.review_websites
            }]
        }];
    }

    const getSocialMediaListItems = () => {
        return [{
            id: 'facebook',
            title: 'Facebook'
        },{
            id: 'instagram',
            title: 'Instagram'
        },{
            id: 'linked_in',
            title: 'Linked-In'
        },{
            id: 'tik_tok',
            title: 'Tik-Tok'
        },{
            id: 'x',
            title: 'X (formerly Twitter)'
        }];
    }

    const getReviewWebsitesListItems = () => {
        return [{
            id: 'amazon',
            title: 'Amazon'
        },{
            id: 'bbb',
            title: 'Better Business Bureau'
        },{
            id: 'facebook',
            title: 'Facebook'
        },{
            id: 'google',
            title: 'Google Business Profile'
        },{
            id: 'product_hunt',
            title: 'Product Hunt'
        },{
            id: 'trust_pilot',
            title: 'Trust Pilot'
        },{
            id: 'yelp',
            title: 'Yelp'
        }];
    }

    return (
        <div style={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            padding: '5vw',
            width: '100%'
        }}>
            <AnimatedElement
            {...utils.animations.get('panel')}
            visible={true}
            style={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column',
                width: '100%'
            }}>
                <div style={{
                    alignItems: 'center',
                    borderRadius: 10,
                    backgroundColor: Appearance.colors.panelBackground(),
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    marginBottom: Appearance.styles.panelSpacing(),
                    maxWidth: 1250,
                    padding: Appearance.styles.panelPadding(),
                    textAlign: 'center',
                    width: '100%'
                }}>
                    <img
                    src={'images/duralink-logo-header.png'}
                    style={{
                        height: 150,
                        marginBottom: Utils.isMobile() ? 24 : 36,
                        objectFit: 'contain',
                        width: 'auto'
                    }} />
                    <span style={{
                        ...Appearance.textStyles.bodyText()
                    }}>{`We're excited to share that our company is hard at work on a brand-new product that we think you're going to love! Designed to make your customer interactions more intuitive and user-friendly, this project aims to tackle some of the everyday challenges we all face. Our passionate team is pouring their hearts into it, and we can't wait to show you what we've been creating. Stay tuned for updates—we think you're going to be just as excited as we are!`}</span>
                </div>
            </AnimatedElement>
            <AnimatedElement
            {...utils.animations.get('panel')}
            visible={true}
            style={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column',
                width: '100%'
            }}>
                <div 
                className={'text-left text-md-center'}
                style={{
                    borderRadius: 10,
                    backgroundColor: Appearance.colors.panelBackground(),
                    borderBottom: `1px solid ${Appearance.colors.divider()}`,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    marginBottom: Appearance.styles.panelSpacing(),
                    maxWidth: 1000,
                    textAlign: 'center',
                    width: '100%'
                }}>
                    <span style={{
                        ...Appearance.textStyles.bodyText(),
                        borderBottom: `1px solid ${Appearance.colors.divider()}`,
                        padding: Appearance.styles.panelPadding(),
                        width: '100%'
                    }}>{`By joining our list of partners, you'll gain early access to essential updates, insights, and sneak peeks of our innovative new offering. This is a fantastic opportunity to stay ahead of the curve and ensure your team is among the first to learn about how our product can benefit your operations.`}</span>
                    <AltFieldMapper
                    fields={getFields()}
                    utils={utils} /> 
                </div>
                <div style={{
                    borderRadius: 10,
                    backgroundColor: Appearance.colors.panelBackground(),
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    maxWidth: 1000,
                    padding: Appearance.styles.panelPadding(),
                    textAlign: 'center',
                    width: '100%'
                }}>
                    <span style={{
                        color: Appearance.colors.subText(),
                        fontSize: 12,
                        fontWeight: 500,
                        lineHeight: 1.2,
                        textAlign: 'left',
                        width: '100%'
                    }}>{`By submitting your information to our service, you acknowledge that your data will be used to provide you with updates and promotional materials related to our products and services. We are committed to protecting your privacy and ensuring your information is handled securely. Please review our privacy policy for more details on how we collect, use, and protect your information.`}</span>
                    <Button 
                    color={'#4A90E2'}
                    containerStyle={{
                        marginTop: 24
                    }}
                    label={'Submit'}
                    loading={loading === 'submit'}
                    onClick={onSubmitClick}
                    type={'large'} />
                </div>
            </AnimatedElement>
        </div>
    )
}

export const HomeSplash = ({ utils }) => {

    const cardSize = Utils.isMobile() ? window.innerWidth / 2 : 250;
    
    const scrollContainer = useRef(null);
    const [animations, setAnimations] = useState({ right: 0 });

    const onAnimationComplete = () => {
        if(animations.right === 0) {
            runScrollContainerAnimations();
            return;
        }
        setAnimations({ right: 0 });
    }

    const getAppItems = () => {
        return [{
            image: 'images/aft-app-icon.png',
            title: 'Applied Fire Technologies'
        },{
            image: 'images/omnishield-app-icon.png',
            title: 'OmniShield'
        },{
            image: 'images/ridebrand-app-icon.png',
            title: 'RideBrand Technologies'
        },{
            image: 'images/flipchart-app-icon.png',
            title: 'FlipChart'
        },{
            image: 'images/dreamcatcher-app-icon.png',
            title: 'DreamCatcher'
        },{
            image: 'images/silicon-union-app-icon.png',
            title: 'Silicon Union'
        },{
            image: 'images/fareryder-app-icon.png',
            title: 'FareRyder'
        },{
            image: 'images/global-data-app-icon.png',
            title: 'Global Data'
        },{
            image: 'images/kride-app-icon.png',
            title: 'K-Ride'
        },{
            image: 'images/ecarra-app-icon.png',
            title: 'eCarra'
        }];
    }
    
    const getContent = () => {

        // prepare sections list 
        let sections = [{
            image: 'images/home-image-01.png',
            position: 'left',
            text: `We believe that software development is more than just coding; it's about building relationships and understanding the unique needs of our clients.`
        },{
            image: 'images/home-image-02.png',
            position: 'right',
            text: `Our passionate team takes the time to listen and collaborate closely with each client, ensuring that every solution is tailored to their specific goals.`
        },{
            image: 'images/home-image-03.png',
            position: 'left',
            text: `We prioritize transparency and open communication, fostering a culture of trust that empowers our clients to be active participants in the development process.`
        },{
            image: 'images/home-image-04.png',
            position: 'right',
            text: `By combining cutting-edge technology with a genuine commitment to our clients' success, we aim to create impactful software that not only meets their expectations but also enhances their business and community.`
        },{
            container: false,
            content: getScrollingAppComponents(),
            text: `We enable businesses to streamline operations with comprehensive automation tools that reduce manual tasks, improve workflows, and boost productivity, ultimately helping organizations adapt to the future of work. By joining the growing network of companies already using our services, businesses can unlock the full potential of connected technologies to stay ahead in a fast-evolving market, driving innovation and enhancing their competitive edge.`
        }];

        // loop through sections and create content components
        return (
            <div style={{
                marginBottom: Appearance.styles.panelSpacing(),
                maxWidth: 1000,
                width: '100%'
            }}>
                {sections.map((section, index) => {
                    if(section.container === false) {
                        return (
                            <AnimatedElement
                            {...utils.animations.get('panel')}
                            animateWithPage={true}
                            key={index}>
                                <div 
                                className={'row ml-0 mr-0 mt-4 mt-md-5'} 
                                style={{
                                    alignItems: 'center',
                                    borderRadius: 10,
                                    backgroundColor: Appearance.colors.panelBackground(),
                                    display: 'flex',
                                    flexDirection: 'column',
                                    marginTop: Appearance.styles.panelPadding(),
                                    width: '100%'
                                }}>
                                    <div style={{
                                        maxWidth: 1250,
                                        padding: Appearance.styles.panelPadding(),
                                        textAlign: 'center',
                                        width: '100%'
                                    }}>
                                        <span style={{
                                            ...Appearance.textStyles.bodyText(),
                                            display: 'block'
                                        }}>{section.text}</span>
                                    </div>
                                    {section.content}
                                </div>
                            </AnimatedElement>
                        )
                    }
                    if(Utils.isMobile() === true) {
                        return (
                            <AnimatedElement
                            {...utils.animations.get('panel')}
                            animateWithPage={true}
                            key={index}>
                                <div
                                className={'row ml-0 mr-0 mt-4 mt-md-5'} 
                                style={{
                                    borderRadius: 10,
                                    backgroundColor: Appearance.colors.panelBackground(),
                                    marginTop: 24,
                                    overflow: 'hidden',
                                    minHeight: 500,
                                    width: '100%'
                                }}>
                                    <div 
                                    className={'col-12 col-md-6 col-lg-6 m-0'}
                                    style={{
                                        alignItems: 'center',
                                        backgroundColor: Appearance.colors.divider(),
                                        display: 'flex',
                                        justifyContent: 'center'
                                    }}>
                                        <img
                                        className={'float'}
                                        src={section.image}
                                        style={{
                                            height: '100%',
                                            objectFit: 'contain',
                                            width: '100%'
                                        }} />
                                    </div>
                                    <div 
                                    className={'col-12 col-md-6 col-lg-6 m-0'}
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        padding: Appearance.styles.panelPadding(),
                                        width: '100%'
                                    }}>
                                        <span style={{
                                            ...Appearance.textStyles.bodyText(),
                                            textAlign: 'center'
                                        }}>{section.text}</span>
                                    </div>
                                </div>
                            </AnimatedElement>
                        )
                    }
                    return (
                        <AnimatedElement
                        {...utils.animations.get('panel')}
                        animateWithPage={true}
                        key={index}>
                            <div
                            className={'row ml-0 mr-0 mt-4 mt-md-5'} 
                            style={{
                                borderRadius: 10,
                                backgroundColor: Appearance.colors.panelBackground(),
                                marginTop: Appearance.styles.panelPadding(),
                                overflow: 'hidden',
                                height: 500,
                                width: '100%'
                            }}>
                                {section.position === 'left' && (
                                    <div 
                                    className={'col-12 col-md-6 col-lg-6 m-0'}
                                    style={{
                                        alignItems: 'center',
                                        backgroundColor: Appearance.colors.divider(),
                                        display: 'flex',
                                        justifyContent: 'center'
                                    }}>
                                        <img
                                        className={'float'}
                                        src={section.image}
                                        style={{
                                            height: '100%',
                                            objectFit: 'contain',
                                            width: '100%'
                                        }} />
                                    </div>
                                )}
                                <div 
                                className={'col-12 col-md-6 col-lg-6 m-0'}
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    padding: Appearance.styles.panelPadding(),
                                    width: '100%'
                                }}>
                                    <span style={{
                                        ...Appearance.textStyles.bodyText()
                                    }}>{section.text}</span>
                                </div>
                                {section.position === 'right' && (
                                    <div 
                                    className={'col-12 col-md-6 col-lg-6 m-0'}
                                    style={{
                                        alignItems: 'center',
                                        backgroundColor: Appearance.colors.divider(),
                                        display: 'flex',
                                        justifyContent: 'center'
                                    }}>
                                        <img
                                        className={'float'}
                                        src={section.image}
                                        style={{
                                            height: '100%',
                                            objectFit: 'contain',
                                            width: '100%'
                                        }} />
                                    </div>
                                )}
                            </div>
                        </AnimatedElement>
                    )
                })}
            </div>
        )
    }

    const getHeader = () => {
        return (
            <AnimatedElement
            {...utils.animations.get('panel')}
            visible={true}>
                <div
                className={'row m-0'} 
                style={{
                    borderRadius: 10,
                    backgroundColor: Appearance.colors.panelBackground(),
                    padding: Appearance.styles.panelPadding(),
                    width: '100%'
                }}>
                    <div 
                    className={'col-12 col-md-6 col-lg-5 m-0 p-0'}
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        padding: 12,
                        width: '100%'
                    }}>
                        <span 
                        className={'text-center text-md-left'}
                        style={{
                            color: Appearance.colors.text(),
                            fontSize: Appearance.textStyles.headerFontSize(),
                            fontWeight: 900,
                            lineHeight: 1.2,
                            marginBottom: Utils.isMobile() ? 24 : 16
                        }}>{'GROW FASTER WITH DURA LABS'}</span>
                        <span 
                        className={'text-center text-md-left'}
                        style={{
                            ...Appearance.textStyles.bodyText()
                        }}>{'At Dura Labs, we\'re not just about software; we\'re about crafting digital experiences that transform the way businesses operate. Our passion lies in developing custom software solutions that are as unique as the challenges our clients face. We believe that your success is our success, and we\'re here to turn your bold ideas into reality.'}</span>
                        <div 
                        className={'row mx-0 mt-4'}
                        style={{
                            maxWidth: 450,
                            width: '100%'
                        }}>
                            <div className={'col-12 col-md-6 pb-2 px-0 pr-md-2'}>
                                <Button 
                                color={Appearance.colors.primary()}
                                containerStyle={{ 
                                    flexGrow: 1
                                }}
                                label={'Learn More'}
                                onClick={utils.navigation.set.bind(this, { view: 'about' })}
                                style={{
                                    
                                }}
                                type={'large'} /> 
                            </div>
                            <div className={'col-12 col-md-6 p-0 pl-md-2'}>
                                <Button
                                color={Appearance.colors.secondary()}
                                containerStyle={{ 
                                    flexGrow: 1
                                }}
                                label={'Contact Us'}
                                onClick={utils.navigation.set.bind(this, { view: 'contact' })}
                                type={'large'} /> 
                            </div>
                        </div>
                    </div>
                    <div className={'d-none d-md-flex col-md-6 col-lg-7 m-0 p-0'}>
                        <img 
                        className={'float'}
                        src={'images/splash-image.png'}
                        style={{
                            height: 'auto',
                            flex: 'none',
                            objectFit: 'contain',
                            width: '100%'
                        }} />
                    </div>
                </div>
            </AnimatedElement>
        )
    }

    const getScrollingAppComponents = () => {
    
        // prepare list of development language and frameworks
        let items = getAppItems();

        // return a horizontally scrolling list 
        return (
            <div 
            ref={scrollContainer}
            style={{
                marginBottom: Utils.isMobile() ? 24 : '2vw',
                maxWidth: '100%',
                overflow: 'hidden',
                width: '100%'
            }}>
                <VelocityComponent
                animation={animations}
                duration={30000}
                complete={onAnimationComplete}>
                    <div style={{
                        alignItems: 'center',
                        display: 'flex',
                        flexDirection: 'row',
                        paddingLeft: Utils.isMobile() ? 24 : '2vw',
                        paddingRight: Utils.isMobile() ? 24 : '2vw',
                        position: 'relative'
                    }}>
                        {items.map((item, index) => {
                            return (
                                <div
                                key={index}
                                style={{
                                    ...Appearance.styles.unstyledPanel(),
                                    alignItems: 'center',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    height: cardSize,
                                    justifyContent: 'center',
                                    marginLeft: 8,
                                    marginRight: 8,
                                    minWidth: cardSize
                                }}>
                                    <img
                                    src={item.image}
                                    style={{
                                        borderRadius: 5,
                                        height: 100,
                                        marginBottom: 12,
                                        objectFit: 'contain',
                                        width: 100
                                    }} />
                                    <span style={{
                                        ...Appearance.textStyles.title()
                                    }}>{item.title}</span>
                                </div>
                            )
                        })}
                    </div>
                </VelocityComponent>
            </div>
        )
    }

    const runScrollContainerAnimations = () => {
        let interval = setInterval(() => {

            // prevent moving forward if a ref has not posted
            if(!scrollContainer.current) {
                return;
            }

            // clear interval
            clearInterval(interval);

            // determine amount of items that can fit on screen at once
            let count = scrollContainer.current.clientWidth / cardSize;

            // get total item count and animate container scroll
            let items = getAppItems();
            setAnimations({ right: (items.length - count) * (cardSize + 32) });

        }, 100);
    }

    useEffect(() => {
        runScrollContainerAnimations();
    }, [scrollContainer.current]);

    return (
        <div style={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            padding: '5vw',
            width: '100%'
        }}>
            {getHeader()}
            {getContent()}
            <StartConverstation
            style={{
                marginTop: Appearance.styles.panelSpacing()
            }} 
            utils={utils} /> 
        </div>
    )
}

export const Login = ({ onLogin }, { index, options, utils }) => {

    const layerID = 'login';

    const [edits, setEdits] = useState({});
    const [layerState, setLayerState] = useState(null);
    const [loading, setLoading] = useState(false);

    const onSignupClick = () => {
        setLayerState('close');
        setTimeout(() => {
            utils.layer.open({
                abstract: Abstract.create({
                    object: User.new(),
                    type: 'users'
                }),
                Component: NewUser.bind(this, { channel: 'duralabs' }),
                id: 'new_user'
            });
        }, 500);
    }

    const onUpdateTarget = props => {
        setEdits(prev => ({ ...prev, ...props }));
    }

    const getFields = () => {
        return [{
            collapsed: null,
            key: 'contact',
            lastItem: true,
            style: {
                border: 'none'
            },
            items: [{
                component: 'textfield',
                key: 'username',
                onChange: text => onUpdateTarget({ username: text }),
                title: 'Username',
                value: edits.username
            },{
                component: 'textfield',
                key: 'password',
                onChange: text => onUpdateTarget({ password: text }),
                props: {
                    isSecure: true
                },
                title: 'Password',
                value: edits.password
            }]
        }];
    }

    const onSubmitClick = async () => {
        try {

            // verify that required fields were filled out
            await validateRequiredFields(getFields);

            // set loading flag and submit request to server
            setLoading('login');
            let { user } = await Request.post(utils, '/users/', {
                ...edits,
                type: 'login'
            });

            // end loading and close layer
            setLoading(false);
            setLayerState('close');

            // notify subscribers that login has completed
            if(typeof(onLogin) === 'function') {
                onLogin(user, { 
                    redirect: { view: 'projects' } 
                });
            }

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue logging into your account. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const getButtons = () => {
        return [{
            color: 'dark',
            id: 'cancel',
            onClick: setLayerState.bind(this, 'close'),
            text: 'Cancel'
        },{
            color: 'primary',
            id: 'login',
            loading: loading === 'login',
            onClick: onSubmitClick,
            text: 'Login'
        }];
    }
    
    return (
        <Layer
        buttons={getButtons()}
        id={layerID}
        index={index}
        options={{
            ...options,
            layerState: layerState,
            loading: loading === true,
            removePadding: true,
            sizing: Utils.isMobile() === true ? 'fullscreen' : 'medium'
        }}>
            <div style={{
                alignItems: 'center',
                borderBottom: `1px solid ${Appearance.colors.divider()}`,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                padding: 24,
                textAlign: 'center',
                width: '100%'
            }}>
                <img
                src={'images/duralabs-logo-header.png'}
                style={{
                    height: 150,
                    marginBottom: Utils.isMobile() ? 24 : 36,
                    width: 'auto'
                }} />
                <span style={{
                    ...Appearance.textStyles.bodyText()
                }}>
                    {`Sign in below with your DuraLabs username and password to continue. Don't have an account? `}
                    <span 
                    className={'cursor-pointer'}
                    onClick={onSignupClick}
                    style={{
                        color: Appearance.colors.primary(),
                        textDecoration: 'underline'
                    }}>{'Signup'}</span>
                </span>
            </div>
            <AltFieldMapper
            fields={getFields()}
            utils={utils} />
        </Layer>
    )
}

export const NewDuraCodeProject = ({ index, options, utils }) => {

    const layerID = 'new_duracode_project';
    const [edits, setEdits] = useState({ details: {}, files: {} });
    const [layerState, setLayerState] = useState(null);
    const [loading, setLoading] = useState(false);
    const [nda, setNda] = useState(null);
    
    const onDownloadNDAClick = async () => {
        try {

            // prevent moving forward if a business name or address have not been provided
            if(!edits.details.business_name || !edits.details.business_address) {
                throw new Error('Please provide your company name and address before downloading your non-disclosure agreement. We use this information to prepare your document.')
            }

            // set loading flag and submit request to server
            setLoading('nda');
            let { url } = await Request.get(utils, '/duracode/', {
                ...edits.details,
                type: 'new_non_disclosure_agreement'
            });

            // show confirmation alert with next steps
            setLoading(false);
            utils.alert.show({
                title: 'All Done!',
                message: 'Your non-disclosure agreement has been prepared and is ready to download. Please sign and upload the document using the "Completed Agreement" field. You will receive a copy of this document signed by Dura Labs LLC before work begins on your project.',
                buttons: [{
                    key: 'download',
                    title: 'Download',
                    style: 'default'
                },{
                    key: 'cancel',
                    title: 'Cancel',
                    style: 'cancel'
                }],
                onClick: key => {
                    if(key === 'download') {
                        setNda(url);
                        window.open(url);
                        return;
                    }
                }
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue preparing your non-disclosure agreement. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onSubmitClick = async () => {
        try {

            // verify that required fields were filled out
            //await validateRequiredFields(getTopFields);
            //await validateRequiredFields(getBottomFields);

            // set loading flag and submit request to server
            setLoading('submit');
            console.log({
                ...edits.details,
                type: 'new_project'
            }, {
                files: Object.keys(edits.files).map(key => ({ key, data: edits.files[key].raw })),
                onProgressChange: ({ percent }) => {
                    console.log(percent);
                }
            })
            await Request.upload(utils, '/duracode/', {
                ...edits.details,
                type: 'new_project'
            }, {
                files: Object.keys(edits.files).map(key => ({ key, data: edits.files[key].raw  })),
                onProgressChange: ({ percent }) => {
                    console.log(percent);
                }
            });

            // show confirmation alert
            utils.alert.show({
                title: 'All Done!',
                message: 'Your DuraCode project has been submitted. You can check the progress of this project on the Projects page. We will update you directly with our progress as we work through your submission.',
                onClick: () => {
                    utils.content.fetch('duracode_projects');
                    setLayerState('close')
                }
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue creating your project. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onUpdateTargetDetail = props => {
        setEdits(prev => {
            return update(prev, {
                details: {
                    $set: {
                        ...prev.details,
                        ...props
                    }
                }
            })
        });
    }

    const onUpdateTargetFiles = props => {
        setEdits(prev => {
            return update(prev, {
                files: {
                    $set: {
                        ...prev.files,
                        ...props
                    }
                }
            })
        });
    }

    const getButtons = () => {
        return [{
            color: 'dark',
            id: 'cancel',
            onClick: setLayerState.bind(this, 'close'),
            text: 'Cancel'
        },{
            color: Appearance.colors.duracode,
            id: 'submit',
            loading: loading === 'submit',
            onClick: onSubmitClick,
            text: 'Submit'
        }];
    }

    const getTopFields = () => {
        return [{
            key: 'details',
            lastItem: false,
            title: 'Company Details',
            items: [{
                component: 'textfield',
                onChange: text => onUpdateTargetDetail({ business_name: text }),
                title: 'Name',
                value: edits.details.business_name
            },{
                component: 'textfield',
                onChange: text => onUpdateTargetDetail({ business_address: text }),
                title: 'Physical Address',
                value: edits.details.business_address
            }]
        }];
    }

    const getBottomFields = () => {
        return [{
            key: 'code',
            title: 'Code and Supporting Files',
            items: [{
                component: 'textfield',
                onChange: text => onUpdateTargetDetail({ name: text }),
                title: 'Project Name',
                value: edits.details.name
            },{
                component: 'file_picker',
                onChange: file => onUpdateTargetFiles({ zip: file }),
                props: {
                    multiple: false,
                    fileTypes: ['zip']
                },
                title: 'Zip File of Project Contents',
                value: edits.files.zip
            },{
                component: 'file_picker',
                onChange: text => onUpdateTargetFiles({ documentation: text }),
                props: {
                    multiple: false
                },
                required: false,
                title: 'Documentation',
                value: edits.files.documentation
            },{
                component: 'textview',
                onChange: text => onUpdateTargetDetail({ notes: text }),
                props: {
                    placeholder: 'Which areas of the project do you need assitance with?'
                },
                title: 'Review Notes',
                value: edits.details.notes
            }]
        }];
    }


    const getFieldComponents = () => {
        return (
            <div style={{
                padding: 24,
                width: '100%'
            }}>
                <AltFieldMapper
                fields={getTopFields()}
                utils={utils} />
                {getNDAComponents()}
                <AltFieldMapper
                fields={getBottomFields()} 
                utils={utils} />
                <span style={{
                    color: Appearance.colors.subText(),
                    display: 'block',
                    fontSize: 12,
                    fontWeight: 500,
                    lineHeight: 2,
                    marginTop: 24,
                    width: '100%'
                }}>{`By submitting your project for review, you confirm that you have the necessary rights and permissions to share this code with our service. This includes ensuring that your submission complies with any applicable licenses, agreements, or confidentiality obligations. Our team respects your intellectual property, and we encourage you to review your permissions before proceeding.`}</span>
            </div>
        )
    }

    const getHeaderComponents = () => {
        return (
            <div style={{
                alignItems: 'center',
                borderBottom: `1px solid ${Appearance.colors.divider()}`,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                padding: 24,
                textAlign: 'center',
                width: '100%'
            }}>
                <img
                src={'images/duracode-logo-header.png'}
                style={{
                    height: 150,
                    marginBottom: Utils.isMobile() ? 24 : 36,
                    width: 'auto'
                }} />
                <span style={{
                    ...Appearance.textStyles.bodyText(),
                    marginBottom: 12,
                }}>{`When submitting your code for review, be sure to include as much detail about your project as possible. A clear description of the project's purpose, features, and any specific areas where you'd like feedback will help our reviewers provide the most helpful and tailored advice. Including relevant documentation, such as a README file or comments within the code, can also make the review process smoother and more effective.`}</span>
            </div>
        )
    }

    const getNDAComponents = () => {
        if(loading === 'nda') {
            return (
                <LayerItem 
                collapsed={false}
                title={'Non-Disclosure Agreement'}>
                    <div style={{
                        ...Appearance.styles.unstyledPanel(),
                        alignItems: 'center',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        padding: 24,
                        width: '100%'
                    }}>
                        <LottieView
                        autoPlay={true}
                        loop={true}
                        source={window.theme === 'dark' ? require('files/lottie/dots-white.json') : require('files/lottie/dots-grey.json')}
                        style={{
                            height: 30,
                            width: 30
                        }}/>
                    </div>
                </LayerItem>
            )
        }
        if(nda === null) {
            return (
                <LayerItem 
                collapsed={false}
                title={'Non-Disclosure Agreement'}>
                    <div style={{
                        ...Appearance.styles.unstyledPanel(),
                        width: '100%'
                    }}>
                        {Views.entry({
                            bottomBorder: false,
                            icon: {
                                path: window.theme === 'dark' ? 'images/general-icon-white.png' : 'images/general-icon-grey.png',
                                imageStyle: {
                                    backgroundColor: null,
                                    borderRadius: 0,
                                    objectFit: 'contain'
                                }
                            },
                            onClick: onDownloadNDAClick,
                            title: 'Download DuraCode NDA',
                            style: {
                                subTitle: {
                                    whiteSpace: 'normal'
                                }
                            },
                            subTitle: 'A completed non-disclosure agreement is required for every project. Click to download your agreement.'
                        })}
                    </div>
                </LayerItem>
            )
        }
        return (
            <AltFieldMapper
            fields={[{
                collapsed: false,
                id: 'nda',
                lastItem: false,
                title: 'Non-Disclosure Agreement',
                items: [{
                    component: 'file_picker',
                    onChange: file => onUpdateTargetFiles({ nda: file }),
                    props: {
                        multiple: false
                    },
                    title: 'Completed Agreement',
                    value: edits.files.nda
                }]
            }]}
            utils={utils} />
        )
    }

    return (
        <Layer 
        buttons={getButtons()}
        id={layerID}
        index={index}
        options={{
            ...options,
            layerState: layerState,
            loading: loading === true,
            removePadding: true,
            sizing: 'medium'
        }}
        utils={utils}> 
            {getHeaderComponents()}
            {getFieldComponents()}
        </Layer>
    )
}

export const NewUser = ({ channel }, { abstract, index, options, utils }) => {

    const layerID = 'new_user';

    const [edits, setEdits] = useState({});
    const [layerState, setLayerState] = useState(null);
    const [loading, setLoading] = useState(false);

    const getFields = () => {
        return [{
            collapsed: null,
            key: 'contact',
            lastItem: true,
            style: {
                border: 'none'
            },
            items: [{
                component: 'textfield',
                key: 'first_name',
                onChange: text => onUpdateTarget({ first_name: text }),
                title: 'First Name',
                value: edits.first_name
            },{
                component: 'textfield',
                key: 'last_name',
                onChange: text => onUpdateTarget({ last_name: text }),
                title: 'Last Name',
                value: edits.last_name
            },{
                component: 'textfield',
                key: 'email_address',
                onChange: text => onUpdateTarget({ email_address: text }),
                title: 'Email Address',
                value: edits.email_address
            },{
                component: 'textfield',
                key: 'username',
                onChange: text => onUpdateTarget({ username: text }),
                title: 'Username',
                value: edits.username
            },{
                component: 'textfield',
                key: 'password',
                onChange: text => onUpdateTarget({ password: text }),
                props: {
                    isSecure: true
                },
                title: 'Password',
                value: edits.password
            }]
        }];
    }

    const onSubmitClick = async () => {
        try {

            // verify that required fields were filled out
            await validateRequiredFields(getFields);

            // set loading flag and submit request to server
            setLoading('submit');
            let { user } = await Request.post(utils, '/users/', {
                ...edits,
                channel: channel,
                type: 'new'
            });

            // end loading and set user object at root
            setLoading(false);
            utils.user.set(user);

            // show confirmation alert
            utils.alert.show({
                title: 'All Done!',
                message: 'Your account has been created and is ready to use. Check your email inbox for next steps.',
                onClick: setLayerState.bind(this, 'close')
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue creating your account. ${e.message || 'An unknown error occurred'}`
            });
        }
    }


    const onUpdateTarget = props => {
        let edits = abstract.object.set(props);
        setEdits(edits);
    }

    const getButtons = () => {
        return [{
            color: 'dark',
            id: 'cancel',
            onClick: setLayerState.bind(this, 'close'),
            text: 'Cancel'
        },{
            color: getChannelColor(),
            id: 'submit',
            loading: loading === 'submit',
            onClick: onSubmitClick,
            text: 'Submit'
        }];
    }

    const getChannelColor = () => {
        switch(channel) {
            case 'duracode':
            return Appearance.colors.duracode;

            default:
            return 'primary';
        }
    }

    const getChannelImage = () => {
        switch(channel) {
            case 'duracode':
            return 'images/duracode-logo-header.png';

            default:
            return 'images/duralabs-logo-header.png';
        }
    }

    const getChannelName = () => {
        switch(channel) {
            case 'duracode':
            return 'DuraCode';

            default:
            return 'Dura Labs';
        }
    }

    const setupEditing = () => {
        let edits = abstract.object.open();
        setEdits(edits);
    }

    useEffect(() => {
        setupEditing();
    }, []);

    return (
        <Layer
        buttons={getButtons()}
        id={layerID}
        index={index}
        options={{
            ...options,
            layerState: layerState,
            loading: loading,
            removePadding: true,
            sizing: Utils.isMobile() === true ? 'fullscreen' : 'medium'
        }}>
            <div style={{
                alignItems: 'center',
                borderBottom: `1px solid ${Appearance.colors.divider()}`,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                padding: 24,
                textAlign: 'center',
                width: '100%'
            }}>
                <img
                src={getChannelImage()}
                style={{
                    height: 150,
                    marginBottom: Utils.isMobile() ? 24 : 36,
                    width: 'auto'
                }} />
                <span style={{
                    ...Appearance.textStyles.bodyText()
                }}>{`Signing up for a ${getChannelName()} account gives you access to all of our products, simplifying your experience and saving you time. This streamlined approach makes it easy to manage your preferences, track your progress, and stay organized—all while enjoying access to our entire suite of offerings.`}</span>
            </div>
            <AltFieldMapper
            fields={getFields()}
            utils={utils} />
            <div style={{
                borderTop: `1px solid ${Appearance.colors.divider()}`,
                padding: 24,
                width: '100%'
            }}>
                <span style={{
                    color: Appearance.colors.subText(),
                    fontSize: 12,
                    fontWeight: 500,
                    lineHeight: 1.2,
                    width: '100%'
                }}>{`By submitting your information to our service, you acknowledge that your data will be used to provide you with updates and promotional materials related to our products and services. We are committed to protecting your privacy and ensuring your information is handled securely. Please review our privacy policy for more details on how we collect, use, and protect your information.`}</span>
            </div>
        </Layer>
    )
}

export const Payments = ({ utils }) => {

    const [loading, setLoading] = useState(true);
    const [payments, setPayments] = useState([]);
    const [upcoming, setUpcoming] = useState([]);

    const getContent = () => {
        if(loading === true) {
            return (
                <div style={{
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    height: 125,
                    justifyContent: 'center',
                    width: '100%'
                }}>
                    <LottieView
                    autoPlay={true}
                    loop={true}
                    source={window.theme === 'dark' ? require('files/lottie/dots-white.json') : require('files/lottie/dots-grey.json')}
                    style={{
                        height: 45,
                        width: 45
                    }}/>
                </div>
            )
        }
        return (
            <>
            <LayerItem 
            collapsed={false}
            title={'Upcoming'}>
                <div style={{
                    ...Appearance.styles.unstyledPanel(),
                    width: '100%'
                }}>
                    {getUpcomingPayments()}
                </div>
            </LayerItem>
            <LayerItem 
            collapsed={false}
            lastItem={true}
            title={'Payment History'}>
                <div style={{
                    ...Appearance.styles.unstyledPanel(),
                    width: '100%'
                }}>
                    {getPayments()}
                </div>
            </LayerItem>
            </>
        )
    }

    const getPayments = () => {
        if(payments.length === 0) {
            return (
                Views.entry({
                    bottomBorder: false,
                    icon: {
                        path: 'images/payments-icon-white.png',
                        imageStyle: {
                            backgroundColor: null,
                            borderRadius: 0
                        }
                    },
                    subTitle: 'There have been no payments processed for your account.',
                    title: 'No Payments Found'
                })
            )
        }
        return payments.map((payment, index) => {
            return (
                Views.entry({
                    subTitle: payment.title
                })
            )
        });
    }

    const getUpcomingPayments = () => {
        if(upcoming.length === 0) {
            return (
                Views.entry({
                    bottomBorder: false,
                    icon: {
                        path: 'images/payments-icon-white.png',
                        imageStyle: {
                            backgroundColor: null,
                            borderRadius: 0
                        }
                    },
                    subTitle: 'There are no payments scheduled for your account.',
                    title: 'No Payments Found'
                })
            )
        }
        return upcoming.map((payment, index) => {
            return (
                Views.entry({
                    subTitle: payment.title
                })
            )
        });
    }

    useEffect(() => {
        setTimeout(setLoading.bind(this, false), 500);
    }, []);

    return (
        <div style={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            padding: '5vw',
            width: '100%'
        }}>
            <AnimatedElement
            {...utils.animations.get('panel')}
            animateWithPage={true}
            style={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column',
                width: '100%'
            }}>
                <div
                className={'text-left text-md-center'}
                style={{
                    alignItems: 'center',
                    borderRadius: 10,
                    backgroundColor: Appearance.colors.panelBackground(),
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    marginBottom: Appearance.styles.panelSpacing(),
                    maxWidth: 1250,
                    padding: Appearance.styles.panelPadding(),
                    width: '100%'
                }}>
                    <span style={{
                        color: Appearance.colors.text(),
                        fontSize: Appearance.textStyles.headerFontSize(),
                        fontWeight: 900,
                        lineHeight: 1.2,
                        marginBottom: 16,
                        textAlign: 'center',
                        width: '100%'
                    }}>{'PAYMENTS'}</span>
                    <span style={{
                        ...Appearance.textStyles.bodyText()
                    }}>{'The payments section is where you can review your transactions, both past and upcoming. This section provides a clear breakdown of completed payments and highlights scheduled or recurring payments, offering a forecast of future financial commitments to assist with budgeting. You can click on a payment to view more details or download an invoice.'}</span>
                </div>
            </AnimatedElement>
            <AnimatedElement
            {...utils.animations.get('panel')}
            animateWithPage={true}
            style={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column',
                width: '100%'
            }}>
                <div style={{
                    borderRadius: 10,
                    backgroundColor: Appearance.colors.panelBackground(),
                    marginBottom: Appearance.styles.panelSpacing(),
                    maxWidth: 1000,
                    overflow: 'hidden',
                    padding: Appearance.styles.panelPadding(),
                    width: '100%'
                }}>
                    {getContent()}
                </div>
            </AnimatedElement>
        </div>
    )
}

export const Privacy = ({ channel }, { utils }) => {

    const getContent = () => {

        let items = [{
            title: 'Collection of Personal Information',
            values: [
                'We do not collect any personal information about you unless you voluntarily provide it to us. However, you may be required to provide certain personal information to us when you elect to use certain products or services available on the Site. These may include: (a) registering for an account on our Site; (b) entering a sweepstakes or contest sponsored by us or one of our partners; (c) signing up for special offers from selected third parties; (d) sending us an email message; (e) submitting your credit card or other payment information when ordering and purchasing products and services on our Site. To wit, we will use your information for, but not limited to, communicating with you in relation to services and/or products you have requested from us. We also may gather additional personal or non-personal information in the future.'
            ]
        },{
            title: 'Use of your Personal Information',
            values: [
                'DuraLink collects and uses your personal information to operate its website(s) and deliver the services you have requested. The personal information collected by DuraLink includes, but may not be limited to, your first name, last name, email address, phone number, and mailing address. Your first and last name are used to personalize the DuraLink mobile app, the DuraLink web app, and DuraLink branded communications. Your email address and phone number are used during account verifications and to keep you up to date with new features, system downtime, and DuraLink branded promotions. Your mailing address is used to deliver location-aware results when you access features that are location dependent. Your profile photo, if you choose to provide a photo, is used to personalize your account with the DuraLink mobile app and the DuraLink web app. To provide a photo, you must choose to allow the DuraLink mobile app to access your photo library. Such access will never be done automatically or without your knowledge. At any time you may elect to terminate your approval to share your photo library with the DuraLink mobile app. DuraLink may also use your personally identifiable information to inform you of other products or services available from DuraLink and its affiliates.'
            ]
        },{
            title: 'Sharing Information with Third Parties',
            values: [
                'DuraLink does not sell, rent or lease its customer lists to third parties. DuraLink may, from time to time, contact you on behalf of external business partners about a particular offering that may be of interest to you. In those cases, your unique personally identifiable information (e-mail, name, address, telephone number) is not transferred to the third party. DuraLink may share data with trusted partners to help perform statistical analysis, send you email or postal mail, provide customer support, or arrange for deliveries. All such third parties are prohibited from using your personal information except to provide these services to DuraLink, and they are required to maintain the confidentiality of your information. DuraLink may disclose your personal information, without notice, if required to do so by law or in the good faith belief that such action is necessary to: (a) conform to the edicts of the law or comply with legal process served on DuraLink or the site; (b) protect and defend the rights or property of DuraLink; and/or (c) act under exigent circumstances to protect the personal safety of users of DuraLink, or the public.'
            ]
        },{
            title: 'Automatically Collected Information',
            values: [
                'Information about your computer hardware and software may be automatically collected by DuraLink. This information can include: your IP address, browser type, domain names, access times and referring website addresses. This information is used for the operation of the service, to maintain quality of the service, and to provide general statistics regarding use of the DuraLink website.'
            ]
        },{
            title: 'Use of Cookies',
            values: [
                'The DuraLink website may use "cookies" to help you personalize your online experience. A cookie is a text file that is placed on your hard disk by a web page server. Cookies cannot be used to run programs or deliver viruses to your computer. Cookies are uniquely assigned to you, and can only be read by a web server in the domain that issued the cookie to you. One of the primary purposes of cookies is to provide a convenience feature to save you time. The purpose of a cookie is to tell the Web server that you have returned to a specific page. For example, if you personalize DuraLink pages, or register with DuraLink site or services, a cookie helps DuraLink to recall your specific information on subsequent visits. This simplifies the process of recording your personal information, such as billing addresses, shipping addresses, and so on. When you return to the same DuraLink website, the information you previously provided can be retrieved, so you can easily use the DuraLink features that you customized. You have the ability to accept or decline cookies. Most Web browsers automatically accept cookies, but you can usually modify your browser setting to decline cookies if you prefer. If you choose to decline cookies, you may not be able to fully experience the interactive features of the DuraLink services or websites you visit.'
            ]
        },{
            title: 'Security of your Personal Information',
            values: [
                'DuraLink secures your personal information from unauthorized access, use, or disclosure. DuraLink uses the following methods for this purpose. We use an open protocol to allow secure authorization in a simple and standard method from web, mobile and desktop applications. Authorization tokens are issued during the account login process and are valid for two weeks. When personal information (such as a credit card number) is transmitted to other websites, it is protected through the use of encryption, such as the Secure Sockets Layer (SSL) protocol. We strive to take appropriate security measures to protect against unauthorized access to or alteration of your personal information. Unfortunately, no data transmission over the Internet or any wireless network can be guaranteed to be 100% secure. As a result, while we strive to protect your personal information, you acknowledge that: (a) there are security and privacy limitations inherent to the Internet which are beyond our control; and (b) security, integrity, and privacy of any and all information and data exchanged between you and us through this Site cannot be guaranteed.'
            ]
        },{
            title: 'Children Under Thirteen',
            values: [
                'DuraLink does not knowingly collect personally identifiable information from children under the age of thirteen. If you are under the age of thirteen, you must ask your parent or guardian for permission to use this website.'
            ]
        },{
            title: 'E-mail Communications',
            values: [
                'From time to time, DuraLink may contact you via email for the purpose of providing announcements, promotional offers, alerts, confirmations, surveys, and/or other general communication.'
            ]
        },{
            title: 'External Data Storage Sites',
            values: [
                'We may store your data on servers provided by third party hosting vendors with whom we have contracted.'
            ]
        },{
            title: 'Changes to this Statement',
            values: [
                'DuraLink reserves the right to change this Privacy Policy from time to time. We will notify you about significant changes in the way we treat personal information by sending a notice to the primary email address specified in your account, by placing a prominent notice on our site, and/or by updating any privacy information on this page. Your continued use of the Site and/or Services available through this Site after such modifications will constitute your: (a) acknowledgment of the modified Privacy Policy; and (b) agreement to abide and be bound by that Policy.'
            ]
        }];

        return items.map((item, index) => {
            return (
                <LayerItem
                collapsed={null}
                key={index}
                title={item.title}>
                    {item.values.map((value, index, values) => {
                        return (
                            <span 
                            key={index}
                            style={{
                                ...Appearance.textStyles.bodyText(),
                                marginBottom: index !== values.length - 1 ? 12 : 0,
                                textAlign: 'left',
                                width: '100%'
                            }}>
                                {value}
                            </span>
                        )
                    })}
                </LayerItem>
            )
        });
    }

    return (
        <div style={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            padding: '5vw',
            width: '100%'
        }}>
            <AnimatedElement
            {...utils.animations.get('panel')}
            animateWithPage={true}
            style={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column',
                textAlign: 'left',
                width: '100%'
            }}>
                <div style={{
                    borderRadius: 10,
                    backgroundColor: Appearance.colors.panelBackground(),
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    marginBottom: Appearance.styles.panelSpacing(),
                    maxWidth: 1250,
                    padding: Appearance.styles.panelPadding(),
                    textAlign: 'left',
                    width: '100%'
                }}>
                    <span style={{
                        color: Appearance.colors.text(),
                        fontSize: Appearance.textStyles.headerFontSize(),
                        fontWeight: 900,
                        lineHeight: 1.2,
                        marginBottom: Appearance.styles.panelPadding(),
                        textAlign: 'center',
                        width: '100%'
                    }}>{'DuraLink Privacy Policy'}</span>
                    {getContent()}
                </div>
            </AnimatedElement>
        </div>
    )
}

export const Projects = ({ utils }) => {

    const limit = 10;
    const offset = useRef(0);

    const [loading, setLoading] = useState(true);
    const [paging, setPaging] = useState(null);
    const [projects, setProjects] = useState([]);

    const onFileClick = file => {
        window.open(file.url);
    }

    const onNewProjectClick = () => {
        utils.layer.open({
            Component: NewDuraCodeProject,
            id: 'new_duracode_project'
        });
    }

    const onProjectClick = (project, evt) => {
        utils.sheet.show({
            items: [{
                key: 'download',
                title: 'Download',
                style: 'default'
            },{
                key: 'view',
                title: 'View',
                style: 'default'
            },{
                key: 'remove',
                title: 'Remove',
                style: 'destructive'
            }],
            target: evt.target
        }, key => {

        });
    }

    const onReviewNotesClick = manifest => {
        utils.alert.show({
            title: 'Review Notes',
            message: manifest.notes
        });
    }

    const getContent = () => {
        if(loading === true) {
            return (
                <div style={{
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    height: 125,
                    justifyContent: 'center',
                    width: '100%'
                }}>
                    <LottieView
                    autoPlay={true}
                    loop={true}
                    source={window.theme === 'dark' ? require('files/lottie/dots-white.json') : require('files/lottie/dots-grey.json')}
                    style={{
                        height: 45,
                        width: 45
                    }}/>
                </div>
            )
        }
        return (
            <>
            <LayerItem 
            collapsed={false}
            lastItem={true}
            title={'My Projects'}>
                {getProjects()}
            </LayerItem>
            </>
        )
    }

    const getProjects = () => {
        if(projects.length === 0) {
            return (
                <div style={{
                    ...Appearance.styles.unstyledPanel(),
                    width: '100%'
                }}>
                    {Views.entry({
                        bottomBorder: false,
                        icon: {
                            path: 'images/payments-icon-white.png',
                            imageStyle: {
                                backgroundColor: null,
                                borderRadius: 0
                            }
                        },
                        subTitle: 'There are no projects associated with your account.',
                        title: 'No Projects Found'
                    })}
                </div>
            )
        }
        return projects.map((project, index) => {
            return (
                <div 
                key={index}
                style={{
                    ...Appearance.styles.unstyledPanel(),
                    marginBottom: index !== projects.length - 1 ? 24 : 0,
                    width: '100%'
                }}>
                    {Views.entry({
                        icon: {
                            path: 'images/duracode-project-icon.png'
                        },
                        //onClick: onProjectClick.bind(this, project),
                        rightContent: (
                            <AltBadge content={project.status || {
                                color: Appearance.colors.grey(),
                                text: 'Pending'
                            }} />
                        ),
                        subTitle: `Submitted ${Utils.format.date(project.date)}`,
                        title: project.name
                    })}
                    {getFiles(project.manifest)}
                </div>
            )
        });
    }

    const getFiles = manifest => {
        let files = manifest.files || {};
        return (
            <>
            {Object.values(files).map((file, index) => {
                return (
                    Views.entry({
                        icon: {
                            path: getFileIconPath(file.type),
                            imageStyle: {
                                backgroundColor: null,
                                borderRadius: 0,
                                boxShadow: null,
                                objectFit: 'contain'
                            }
                        },
                        onClick: onFileClick.bind(this, file),
                        key: index,
                        subTitle: file.type,
                        title: file.name
                    })
                )
            })}
            {Views.entry({
                bottomBorder: false,
                icon: {
                    path: getFileIconPath('txt'),
                    imageStyle: {
                        backgroundColor: null,
                        borderRadius: 0,
                        boxShadow: null,
                        objectFit: 'contain'
                    }
                },
                onClick: onReviewNotesClick.bind(this, manifest),
                subTitle: 'text/plain',
                title: 'Review Notes'
            })}
            </>
        );
    }

    const fetchProjects = async () => {
        try {

            // set loading flag and submit request to server
            setLoading(true);
            let { paging, projects } = await Request.get(utils, '/duracode/', {
                limit: limit,
                offset: offset.current,
                type: 'projects'
            });

            setLoading(false);
            setPaging(paging);
            setProjects(projects);

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving your projects. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    useEffect(() => {
        fetchProjects();
        utils.content.subscribe('projects', ['duracode_projects'], {
            onFetch: fetchProjects
        });
        return () => {
            utils.content.unsubscribe('projects');
        }
    }, []);

    return (
        <div style={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            padding: '5vw',
            width: '100%'
        }}>
            <AnimatedElement
            {...utils.animations.get('panel')}
            animateWithPage={true}
            style={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column',
                width: '100%'
            }}>
                <div
                className={'text-left text-md-center'}
                style={{
                    alignItems: 'center',
                    borderRadius: 10,
                    backgroundColor: Appearance.colors.panelBackground(),
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    marginBottom: Appearance.styles.panelSpacing(),
                    maxWidth: 1250,
                    padding: Appearance.styles.panelPadding(),
                    width: '100%'
                }}>
                    <span style={{
                        color: Appearance.colors.text(),
                        fontSize: Appearance.textStyles.headerFontSize(),
                        fontWeight: 900,
                        lineHeight: 1.2,
                        marginBottom: 16,
                        textAlign: 'center',
                        width: '100%'
                    }}>{'PROJECTS'}</span>
                    <span style={{
                        ...Appearance.textStyles.bodyText()
                    }}>{`The projects section is where you can track all the projects you've submitted for review with DuraCode. Each project is listed with key details like its name, submission date, and current review status, so you always know what’s happening. You can easily click into any project to see more information, check reviewer feedback, or follow up on revisions. Whether you’re managing one project or several, this section keeps everything organized and accessible in one convenient place.`}</span>
                </div>
            </AnimatedElement>
            <AnimatedElement
            {...utils.animations.get('panel')}
            animateWithPage={true}
            style={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column',
                width: '100%'
            }}>
                <div style={{
                    borderRadius: 10,
                    backgroundColor: Appearance.colors.panelBackground(),
                    marginBottom: Appearance.styles.panelSpacing(),
                    maxWidth: 1000,
                    overflow: 'hidden',
                    width: '100%'
                }}>
                    {projects.length > 0 && (
                        <div style={{
                            padding: Appearance.styles.panelPadding(),
                            width: '100%'
                        }}>
                            {getContent()}
                            <div style={{
                                marginTop: 24,
                                width: '100%'
                            }}>
                                <Button
                                color={Appearance.colors.duracode}
                                label={'Start a New Project'}
                                onClick={onNewProjectClick}
                                type={'large'} /> 
                            </div>
                        </div>
                    )}
                    {projects.length === 0 && (
                        <div style={{
                            alignItems: 'center',
                            borderTop: `1px solid ${Appearance.colors.divider()}`,
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            padding: Appearance.styles.panelPadding(),
                            width: '100%'
                        }}>
                            <span 
                            className={'mb-4'}
                            style={{
                                ...Appearance.textStyles.bodyText()
                            }}>{`Ready to take your code to the next level? Submitting a project for review is a great way to get valuable insights and expert feedback to improve your skills and build stronger, more efficient code. Whether you're tackling a tricky feature, polishing a portfolio piece, or just looking to learn, our reviewers are here to help. It's easy to submit, and you'll get actionable suggestions tailored to your project. Don't wait—start your code review journey today!`}</span>
    
                            <Button
                            color={Appearance.colors.duracode}
                            containerStyle={{
                                maxWidth: 350,
                                width: '100%'
                            }}
                            label={'Start a New Project'}
                            onClick={onNewProjectClick}
                            type={'large'} /> 
                        </div>
                    )}
                </div>
            </AnimatedElement>
        </div>
    )
}

export const StartConverstation = ({ style, utils }) => {

    return (
        <AnimatedElement
        {...utils.animations.get('panel')}
        animateWithPage={true}
        style={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            ...style
        }}>
            <div style={{
                alignItems: 'center',
                borderRadius: 10,
                backgroundColor: Appearance.colors.panelBackground(),
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                marginBottom: Appearance.styles.panelSpacing(),
                maxWidth: 1250,
                padding: Appearance.styles.panelPadding(),
                textAlign: 'center',
                width: '100%'
            }}>
                <span style={{
                    color: Appearance.colors.text(),
                    fontSize: Appearance.textStyles.headerFontSize(),
                    fontWeight: 900,
                    lineHeight: 1.2,
                    marginBottom: Utils.isMobile() ? 24 : 16
                }}>{`LET'S START A CONVERSATION`}</span>
                <span 
                className={'mb-4'}
                style={{
                    ...Appearance.textStyles.bodyText()
                }}>{`If you're looking to bring your ideas to life or tackle a challenging project, we're here to help! Whether you have a fully formed concept or just a spark of an idea, our team is ready to listen and guide you through the development process. Don't hesitate to reach out—let's discuss how we can turn your aspirations into reality.`}</span>

                <Button
                color={Appearance.colors.primary()}
                containerStyle={{
                    maxWidth: 350,
                    width: '100%'
                }}
                label={'Get Started'}
                onClick={utils.navigation.set.bind(this, { view: 'contact' })}
                type={'large'} /> 
            </div>
        </AnimatedElement>
    )
}