class AbstractClass {

    object = null;
    type = null;

    constructor() {
        return this;
    }

    compare = (target, callback) => {

        // compare two astract objects if applicable
        if(typeof(target.getID) === 'function') {
            if(this.getTag() === target.getTag()) {
                if(typeof(callback) === 'function') {
                    callback();
                }
                return this.object;
            }
            return target.object;
        }

        // compare and abstract object and a standard javascript object
        if(this.getID() !== target.id) {
            return target;
        }
        if(typeof(callback) === 'function') {
            callback();
        }
        return this.object;
    }

    create = (props = {}) => {
        this.type = props.type;
        this.object = props.object;
        return this;
    }


    getID = () => {
        return this.type === 'user' ? this.object.user_id : this.object.id
    }

    getTag = () => {
        return `${this.type}-${this.getID()}`;
    }

    getTitle = () => {
        switch(this.type) {
            case 'user':
            return this.object.user_id ? `Account for ${this.object.full_name}` : 'New User';

            default:
            return 'Unknown Object';
        }
    }

    isNewTarget = () => {
        return this.getID() ? false : true;
    }

    isSame = abstract => {
        return this.getTag() === abstract.getTag();
    }
}

export default {
    create: props => new AbstractClass().create(props)
}
