import React, { useEffect, useState } from 'react';
import update from 'immutability-helper';

import Appearance from 'styles/Appearance.js';
import ListField from 'views/ListField.js';

const MultipleListField = props => {

    const { onAddNew, onChange, onRenderResult, placeholder, value } = props;
    const [items, setItems] = useState(Array.isArray(value) ? value : []);

    const onItemChange = item => {
        if(items.find(prev => prev.id === item.id)) {
            return;
        }
        let next = update(items, { $push: [item] });
        setItems(next);
        if(typeof(onChange) === 'function') {
            onChange(next);
        }
    }

    const onRemoveItem = (index, evt) => {

        // stop event propagation if applicable
        if(evt && typeof(evt.stopPropagation) === 'function') {
            evt.stopPropagation();
        }

        // update list of selected items
        let next = update(items, { $splice: [[ index, 1 ]] });
        setItems(next);

        // notify subscribers of data change
        if(typeof(onChange) === 'function') {
            onChange(next);
        }
    }

    useEffect(() => {
        setItems(Array.isArray(value) ? value : []);
    }, [value]);

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%'
        }}>
            <ListField
            items={props.items}
            onAddNew={onAddNew}
            onChange={onItemChange}
            placeholder={placeholder}
            preserveResult={false} />
            {items.length > 0 && (
                <div style={{
                    ...Appearance.styles.unstyledPanel(),
                    marginTop: 8
                }}>
                    {items.map((item, index) => {
                        if(typeof(onRenderResult) === 'function') {
                            return onRenderResult(item, index, items, onRemoveItem);
                        }
                        return (
                            <div
                            key={index}
                            style={{
                                alignItems: 'center',
                                borderBottom: index !== items.length - 1 ? `1px solid ${Appearance.colors.divider()}` : null,
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                padding: '8px 12px 8px 12px'
                            }}>
                                <span style={{
                                    ...Appearance.textStyles.key(),
                                    minWidth: null,
                                    whiteSpace: 'normal'
                                }}>{item.title}</span>
                                <img
                                className={'text-button'}
                                onClick={onRemoveItem.bind(this, index)}
                                src={`images/red-x-icon.png`}
                                style={{
                                    height: 18,
                                    marginLeft: 8,
                                    objectFit: 'contain',
                                    opacity: 1,
                                    width: 18
                                }} />
                            </div>
                        )
                    })}
                </div>
            )}
        </div>
    )
}

export default MultipleListField;
