import React, { useEffect, useRef, useState } from 'react';
import 'styles/main.css';

import { animated, useSpring } from '@react-spring/web';
import moment from 'moment-timezone';
import update from 'immutability-helper';

import API from 'files/api.js';
import{ About, Account, Contact, DuraCode, DuraLink, HomeSplash, Login, Payments, Privacy, Projects } from 'managers/Components.js';
import Alert from 'views/Alert.js';
import AlertStack from 'views/AlertStack.js';
import AnimatedElement from 'views/AnimatedElement.js';
import Appearance from 'styles/Appearance.js';
import Content from 'managers/Content.js';
import { EndIndex } from 'structure/Layer.js';
import DatePicker from 'views/DatePicker.js';
import Events from 'managers/Events.js';
import QueryString from 'query-string';
import Request from 'files/Request.js';
import Sheet from 'views/Sheet.js';
import Utils from 'files/Utils.js';
import Views from 'views/Main';

const ContentManager = Content.new();
const EventsManager = Events.new();

const panels = {
    about: [{
        id: 'about',
        Component: About
    }],
    account: [{
        id: 'account',
        Component: Account
    }],
    contact: [{
        id: 'contact',
        Component: Contact
    }],
    duracode: [{
        id: 'duracode',
        Component: DuraCode
    }],
    duralink: [{
        id: 'duralink',
        Component: DuraLink
    }],
    duralink_privacy: [{
        id: 'privacy',
        Component: Privacy.bind(this, {channel: 'duralink'})
    }],
    home: [{
        id: 'home',
        Component: HomeSplash
    }],
    payments: [{
        id: 'payments',
        Component: Payments
    }],
    projects: [{
        id: 'projects',
        Component: Projects
    }],
    services: []
}

const App = () => {

    const accountElementRef = useRef(null);
    const layersRef = useRef(null);
    const userRef = useRef(null);

    const [active, setActive] = useState({ view: null });
    const [alerts, setAlerts] = useState([]);
    const [badgeAnimations, setBadgeAnimations] = useSpring(() => ({
        config: { mass: 1, tension: 180, friction: 26 },
        opacity: 0,
        transform: 'scale(0.5)'
    }));
    const [contentAnimations, setContentAnimations] = useSpring(() => ({
        config: { mass: 1, tension: 180, friction: 16 },
        opacity: 1,
        top: 0,
    }));
    const [datePicker, setDatePicker] = useState(null);
    const [layers, setLayers] = useState([]);
    const [layerIndex, setLayerIndex] = useState([]);
    const [loader, setLoader] = useState(null);
    const [logoAnimations, setLogoAnimations] = useSpring(() => ({
        config: { mass: 1, tension: 180, friction: 26 },
        left: -50,
        opacity: 0
    }));
    const [menuAnimations, setMenuAnimations] = useSpring(() => ({
        config: { mass: 1, tension: 180, friction: 26 },
        right: -50,
        opacity: 0
    }));
    const [menuDrawerContentAnimations, setMenuDrawerContentAnimations] = useSpring(() => ({
        config: { mass: 1, tension: 180, friction: 18 },
        left: 0
    }));
    const [menuDrawerAnimations, setMenuDrawerAnimations] = useSpring(() => ({
        config: { mass: 1, tension: 180, friction: 18 },
        left: window.innerWidth
    }));
    const [menuExpanded, setMenuExpanded] = useState(false);
    const [selectedNavigationItem, setSelectedNavigationItem] = useState(false);
    const [nonce, setNonce] = useState(moment().unix());
    const [sheet, setSheet] = useState(null);
    const [size, setSize] = useState({ height: window.innerHeight, width: window.innerWidth });
    const [theme, setTheme] = useState('light');
    const [user, setUser] = useState(null);

    const onAccountClick = () => {
        utils.sheet.show({
            items: getAccountItems().concat([{
                key: 'cancel',
                title: 'Close',
                style: 'cancel'
            }]),
            position: 'bottom',
            sort: false,
            target: accountElementRef.current
        }, onParseAccountClick);
    }
    
    const onCloseLayer = layerID => {

        // filter layer id out of layer indexes
        setLayerIndex(layerIndex => {
            return update(layerIndex, {
                $apply: ids => ids.filter(id => id !== layerID)
            });
        });

        setLayers(layers => {

            // update layer and set visible flag to false
            let next = update(layers, {
                $apply: layers => layers.map(layer => {
                    if(layer.id === layerID) {
                        layer.visible = false;
                    }
                    return layer;
                })
            });

            // determine if at least one layer is still visible
            let targets = next.filter(layer => layer.visible !== false);
            if(targets.length === 0) {
                console.log('layers reset');
                document.body.style.overflowY = 'scroll';
                return [];
            }
            return next;
        });
    }

    const onLayerReposition = ({ id, position }) => {

        let index = layers.findIndex(layer => id === layer.id);
        if(index < 0) {
            console.log('no layer index');
            return;
        }
        setLayers(layers => {
            return update(layers, {
                [index]: {
                    position: {
                        $set: position
                    }
                }
            });
        });
    }

    const onLogin = (user, options = {}) => {

        // write login token to local storage
        localStorage.setItem('user.refresh_token', user.refresh_token);

        // update local state with user object
        userRef.current = user;
        setUser(user);

        // determine if a redirect was requested
        if(options.redirect) {
            setActive(options.redirect);
        }
    }

    const onLoginClick = () => {
        
        // hide navigation menu for mobile devices
        if(Utils.isMobile() === true) {
            setMenuExpanded(false);
            setSelectedNavigationItem(null);
        }

        // open account signup layer
        utils.layer.open({
            Component: Login.bind(this, { onLogin }),
            id: 'login'
        });
    }

    const onLoginWithRefreshToken = async token => {
        try {

            // submit login request to server
            let { user } = await Request.post(utils, '/users/', {
                refresh_token: token,
                type: 'login'
            });
            onLogin(user);

        } catch(e) {
            console.error(e.message);
        }
    }

    const onMouseEnterMainContent = () => {

        // deselect currently selected navigation item some menu will hide
        setSelectedNavigationItem(false);
    }

    const onOpenLayer = nextLayer => {

        if(layers.find(layer => {
            return layer.id === nextLayer.id && layer.visible !== false
        })) {
            utils.alert.show({
                title: nextLayer.abstract ? nextLayer.abstract.getTitle() : 'Just a Second',
                message: `There is already a window open for ${nextLayer.abstract ? `"${nextLayer.abstract.getTitle()}"` : 'this information'}`
            });
            return;
        }

        setTimeout(() => {
            setLayers(layers => update(layers, {
                $push: [nextLayer]
            }))
            setLayerIndex(layerIndex => update(layerIndex, {
                $unshift: [nextLayer.id]
            }))
        }, 0)
    }

    const onParseAccountClick = key => {
        switch(key) {
            case 'account':
            break;

            case 'payments':
            case 'projects':
            setActive({ view: key });
            break;

            case 'logout':
            onRequestLogout();
            break;
        }
    }

    const onRequestLogout = () => {
        utils.alert.show({
            title: 'Logout',
            message: 'Are you sure that you want to logout of your account?',
            buttons: [{
                key: 'confirm',
                title: 'Yes',
                style: 'destructive'
            },{
                key: 'cancel',
                title: 'Cancel',
                style: 'default'
            }],
            onClick: key => {
                if(key === 'confirm') {
                    localStorage.removeItem('user.refresh_token');
                    setActive({ view: 'home' });
                    setUser(null);
                    return;
                }
            }
        });
    }

    const onSelectedNavigationItemChange = () => {
        if(selectedNavigationItem === null) {
            setMenuDrawerContentAnimations({ left: 0 });
            return;
        }
        if(menuExpanded === true) {
            setMenuDrawerContentAnimations({ left: -size.width });
        }
        if(menuExpanded === false) {
            setSelectedNavigationItem(null);
        }
    }

    const onSetLayerIndex = layerID => {

        let index = layers.findIndex(layer => layer.id === layerID);
        if(index < 0) {
            console.log('no layer index');
            return;
        }
        setLayers(layers, update(layers, {
            $apply: layers => layers.map(layer => {
                layer.moveToFront = layer.id === layerID;
                return layer;
            })
        }))

        let _index = layerIndex.findIndex(id => id === layerID);
        setLayerIndex(layerIndex, update(layerIndex, {
            $splice: [
                [_index, 1],
                [0, 0, layerID]
            ]
        }))
    }

    const onSetStyleSheetProperties = () => {
        setTheme(window.theme);
        document.body.className = window.theme;
        document.documentElement.style.setProperty('--theme', window.theme);
        document.documentElement.style.setProperty('--text', Appearance.colors.text());
        document.documentElement.style.setProperty('--textfield', Appearance.colors.textField());
        document.documentElement.style.setProperty('--soft_border', Appearance.colors.softBorder());
        document.querySelector('meta[name="theme-color"]').setAttribute("content", Appearance.colors.layerBackground());
    }

    const onSizeChange = () => {
        setSize({ height: window.innerHeight, width: window.innerWidth });
    }

    const onToggleMobileMenu = () => {
        setMenuExpanded(val => !val);
        setSelectedNavigationItem(null);
    }

    const onUpdateTheme = evt => {
        window.theme = 'dark'; //evt.matches ? 'dark' : 'light';
        onSetStyleSheetProperties();
        setNonce(moment().unix());
    }

    const getAccountComponents = () => {
        if(user) {
            return (
                <div 
                ref={accountElementRef}
                style={{
                    ...Appearance.styles.unstyledPanel(),
                    display: 'flex',
                    flexDirection: 'column',
                    textAlign: 'left'
                }}>
                    {Views.entry({
                        bottomBorder: false,
                        icon: {
                            path: 'images/user-placeholder.png'
                        },
                        onClick: onAccountClick,
                        style: {
                            padding: '8px 14px 8px 14px',
                        },
                        subTitle: user.email_address,
                        title: user.full_name
                    })}
                </div>
            )
        }
        return (
            <span 
            className={'text-button'}
            onClick={onLoginClick}
            style={{
                ...Appearance.textStyles.title(),
                marginRight: 32
            }}>{'Login'}</span>
        )
    }

    const getAccountItems = () => {
        return [{
            key: 'payments',
            title: 'Payments',
            style: 'default'
        },{
            key: 'projects',
            title: 'Projects',
            style: 'default'
        },{
            key: 'settings',
            title: 'Settings',
            style: 'default'
        },{
            key: 'logout',
            title: 'Logout',
            style: 'destructive'
        }].map(item => ({
            ...item,
            onClick: () => {
                onParseAccountClick(item.key)
                if(Utils.isMobile() === true) {
                    setMenuExpanded(false);
                }
            }
        }));
    }

    const getApps = () => {
        return [{
            icon: 'images/duracode-app-icon.jpg',
            key: 'duracode',
            title: 'DuraCode'
        },{
            icon: 'images/duralink-app-icon.jpg',
            key: 'duralink',
            title: 'DuraLink'
        }];
    }

    const getAppNavigationComponents = () => {
        let components = getApps().map((app, index, apps) => {
            return (
                <div 
                className={'text-button'}
                key={index}
                onClick={setActive.bind(this, { view: app.key })}
                style={{
                    alignItems: 'center',
                    borderBottom: index !== apps.length - 1 && `1px solid ${Appearance.colors.divider()}`,
                    display: 'flex',
                    flexDirection: 'row',
                    padding: 12,
                    width: '100%'
                }}>
                    <img 
                    src={app.icon}
                    style={{
                        borderRadius: 8,
                        height: 30,
                        marginRight: 12,
                        width: 30
                    }} />
                    <span style={{
                        ...Appearance.textStyles.title(),
                        flexGrow: 1,
                        paddingRight: 36
                    }}>{app.title}</span>
                    <img
                    src={'images/next-arrow-grey-small.png'}
                    style={{
                        width: 12,
                        height: 12,
                        objectFit: 'contain',
                        marginLeft: 8,
                        opacity: 0.75
                    }} />
                </div>
            )  
        });
        return getNavigationContainer(components);
    }

    const getDesktopVersionDetails = () => {
        return Utils.isMobile() === false && (
            <AnimatedElement
            {...utils.animations.get('panel')}
            animateWithPage={true}
            persist={false}>
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    paddingTop: 24,
                    paddingBottom: 48,
                    textAlign: 'center'
                }}>
                    <span style={{
                        ...Appearance.textStyles.subTitle(),
                        color: Appearance.colors.text()
                    }}>{`© 2024 Dura Labs LLC. All rights reserved.`}</span>
                    <span style={{
                        ...Appearance.textStyles.subTitle(),
                    }}>{`Version ${API.app_version} Build ${API.build}`}</span>
                </div>
            </AnimatedElement>
        )
    }

    const getLayers = () => {
        return layers.map(({ id, abstract, options, title, visible, Component }, index) => {

            if(visible === false) {
                // must return null instead of using filter
                // using filter for visible does not preseve other visible layers
                return null;
            }
            return (
                <Component
                key={index}
                title={title}
                index={index}
                utils={utils}
                abstract={abstract}
                options={{
                    ...options,
                    index: index,
                    onClose: onCloseLayer,
                    onReposition: onLayerReposition,
                    onSetLayerIndex: onSetLayerIndex,
                    zIndex: EndIndex - layerIndex.findIndex(indexID => id === indexID)
                }}/>
            )
        });
    }

    const getHeader = () => {

        // prepare naivgation items
        let items = [/*{
            key: 'services',
            title: 'Services'
        },*/{
            key: 'apps',
            title: 'Apps and Services'
        },{
            key: 'contact',
            onClick: setActive.bind(this, { view: 'contact' }),
            title: 'Contact'
        },{
            key: 'about',
            onClick: setActive.bind(this, { view: 'about' }),
            title: 'About Us'
        },{
            key: 'account',
            title: 'My Account'
        }];

        // determine if current device is a mobile device
        if(Utils.isMobile() === true) {
            return (
                <>
                <div style={{
                    alignItems: 'center',
                    backgroundColor: Appearance.colors.layerBackground(),
                    borderBottom: `1px solid ${Appearance.colors.divider()}`,
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    marginBottom: 12,
                    padding: 20,
                    position: 'relative',
                    textAlign: 'center',
                    width: '100%'
                }}>
                    <animated.div 
                    className={'text-button'}
                    onClick={setActive.bind(this, { view: 'home' })}
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-start',
                        paddingLeft: 8,
                        position: 'relative',
                        ...logoAnimations
                    }}>
                        <img 
                        src={theme === 'dark' ? 'images/logo-text-white.png' : 'images/logo-text-grey.png'}
                        style={{
                            height: 20,
                            width: 'auto'
                        }} />
                    </animated.div>
                    <animated.img 
                    className={'text-button'}
                    onClick={onToggleMobileMenu}
                    src={`images/navigation-icon-${theme}.png`}
                    style={{
                        height: 30,
                        objectFit: 'contain',
                        position: 'relative',
                        width: 30,
                        ...menuAnimations
                    }} />
                </div>
                <animated.div style={{
                    backgroundColor: Appearance.colors.layerBackground(),
                    bottom: 0,
                    height: '100%',
                    overflow: 'hidden',
                    position: 'fixed',
                    top: 0,
                    width: '100%',
                    zIndex: 5500,
                    ...menuDrawerAnimations
                }}>
                    <animated.div style={{
                         alignItems: 'center',
                         display: 'flex',
                         flexDirection: 'row',
                         height: '100dvh',
                         overflow: 'hidden',
                         position: 'relative',
                         width: size.width * 2,
                         ...menuDrawerContentAnimations
                    }}>
                        <div style={{
                            alignItems: 'center',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-around',
                            height: '100%',
                            padding: `128px 20px 148px 20px`,
                            width: size.width
                        }}>
                            {items.map((item, index) => {
                                if(!user && item.key === 'account') {
                                    return (
                                        <span 
                                        className={'text-button'}
                                        key={index}
                                        onClick={onLoginClick}
                                        style={{
                                            ...Appearance.textStyles.layerItemTitle(),
                                            fontSize: 24
                                        }}>{'Login'}</span>
                                    )
                                }
                                return (
                                    <span 
                                    className={'text-button'}
                                    key={index}
                                    onClick={item.onClick || setSelectedNavigationItem.bind(this, item.key)}
                                    style={{
                                        ...Appearance.textStyles.layerItemTitle(),
                                        fontSize: 24,
                                        ...item.key === active.view && {
                                            color: Appearance.colors.primary()
                                        }
                                    }}>{item.title}</span>
                                )
                            })}
                        </div>
                        <div style={{
                            alignItems: 'center',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-around',
                            height: '100%',
                            padding: `128px 20px 148px 20px`,
                            position: 'relative',
                            width: size.width
                        }}>
                            {getInnerDrawerComponents()}
                            <img
                            className={'text-button'}
                            onClick={setSelectedNavigationItem.bind(this, null)}
                            src={`images/back-arrow-${theme}.png`}
                            style={{
                                height: 25,
                                objectFit: 'contain',
                                position: 'absolute',
                                left: 20,
                                top: 20,
                                width: 18
                            }} />
                        </div>
                    </animated.div>
                    <img
                    className={'text-button'}
                    onClick={onToggleMobileMenu}
                    src={`images/close-button-${theme}.png`}
                    style={{
                        height: 25,
                        objectFit: 'contain',
                        position: 'absolute',
                        right: 20,
                        top: 20,
                        width: 25
                    }} />
                    <div style={{
                        bottom: 0,
                        padding: 24,
                        position: 'absolute',
                        textAlign: 'center',
                        width: '100%'
                    }}>
                        <span style={{
                            ...Appearance.textStyles.subTitle(),
                            display: 'block'
                        }}>{`Version ${API.app_version} Build ${API.build}`}</span>
                    </div>
                </animated.div>
                </>
            )
        }
        
        // fallback to rendering content for tablets and desktop 
        return (
            <div style={{
                alignItems: 'center',
                backgroundColor: Appearance.colors.layerBackground(),
                borderBottom: `1px solid ${Appearance.colors.divider()}`,
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                marginBottom: 12,
                padding: '10px 10px 10px 20px',
                textAlign: 'center',
                width: '100vw'
            }}>
                <animated.div 
                className={'text-button'}
                onClick={setActive.bind(this, { view: 'home' })}
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                    paddingLeft: 8,
                    position: 'relative',
                    width: 'calc(50vw - 50px)',
                    ...logoAnimations
                }}>
                    <img 
                    src={theme === 'dark' ? 'images/logo-text-white.png' : 'images/logo-text-grey.png'}
                    style={{
                        height: 25,
                        width: 'auto'
                    }} />
                </animated.div>
                <animated.img 
                className={'text-button'}
                onClick={setActive.bind(this, { view: 'home' })}
                src={'images/logo.png'}
                style={{
                    height: 40,
                    objectFit: 'contain',
                    width: 40,
                    ...badgeAnimations,
                    ...size.width < 1300 && {
                        opacity: 0
                    }
                }} />
                <animated.div style={{
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-end',
                    position: 'relative',
                    width: 'calc(50vw - 50px)',
                    ...menuAnimations
                }}>
                    {items.map((item, index) => {
                        if(item.key === 'account') {
                            return (
                                <div 
                                key={index}
                                style={{
                                    alignItems: 'center',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    position: 'relative'
                                }}>
                                    {getAccountComponents()}
                                </div>
                            )
                        }
                        return (
                            <div 
                            key={index}
                            style={{
                                alignItems: 'center',
                                display: 'flex',
                                flexDirection: 'column',
                                marginRight: 32,
                                position: 'relative'
                            }}>
                                <span 
                                className={'text-button'}
                                onClick={item.onClick}
                                onMouseEnter={setSelectedNavigationItem.bind(this, item.key)}
                                style={{
                                    ...Appearance.textStyles.title(),
                                    ...item.key === active.view && {
                                        color: Appearance.colors.primary()
                                    }
                                }}>{item.title}</span>
                                {selectedNavigationItem === item.key && getNavigationComponents(item.key)}
                            </div>
                        )
                    })}
                </animated.div>
            </div>
        )
    }

    const getInnerDrawerComponents = () => {
        switch(selectedNavigationItem) {
            case 'apps':
            return getApps().map((app, index) => {
                return (
                    <span 
                    className={'text-button'}
                    key={index}
                    onClick={setActive.bind(this, { view: app.key })}
                    style={{
                        ...Appearance.textStyles.layerItemTitle(),
                        fontSize: 24
                    }}>{app.title}</span>
                )
            });

            case 'account':
            return getAccountItems().map((entry, index) => {
                return (
                    <span 
                    className={'text-button'}
                    key={index}
                    onClick={entry.onClick || setActive.bind(this, { view: entry.key })}
                    style={{
                        ...Appearance.textStyles.layerItemTitle(),
                        fontSize: 24
                    }}>{entry.title}</span>
                )
            });

            default:
            return null;
        }
    }

    const getMainContent = () => {

        let targets = panels[active.view] || [];
        return (
            <animated.div style={{
                display: 'flex',
                flexDirection: 'column',
                position: 'relative',
                width: '100%',
                ...contentAnimations
            }}>
                {getHeader()}
                {getLayers()}
                <div 
                onMouseEnter={onMouseEnterMainContent}
                style={{
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    position: 'relative',
                    width: '100%'
                }}>
                    <div
                    className={'row p-0 m-0'}
                    style={{
                        position: 'relative',
                        width: '100%'
                    }}>
                        {targets.map(({ Component }, index) => (
                            <Component
                            index={index} 
                            key={index}
                            utils={utils}/>
                        ))}
                    </div>
                </div>
            </animated.div>
        )
    }

    const getNavigationComponents = () => {
        switch(selectedNavigationItem) {
            case 'apps':
            return getAppNavigationComponents();

            default:
            return null;
        }
    }

    const getNavigationContainer = (components, lastItem) => {
        return (
            <div 
            onMouseLeave={setSelectedNavigationItem.bind(this, false)}
            style={{
                alignItems: lastItem ? 'flex-end' : 'center',
                display: 'flex',
                flexDirection: 'column',
                position: 'absolute',
                top: 40,
                right: lastItem ? 0 : null,
                zIndex: 7500
            }}>
                <img 
                src={theme === 'dark' ? 'images/floating-menu-carrot-grey.png' : 'images/floating-menu-carrot-white.png'}
                style={{
                    height: 25,
                    objectFit: 'contain',
                    position: 'relative',
                    top: 8,
                    right: lastItem ? 12 : null,
                    width: 25
                }} />
                <div style={{
                    background: Appearance.colors.layerBackground(),
                    border: `1px solid ${Appearance.colors.divider()}`,
                    borderRadius: 12,
                    boxShadow: '5px 5px 20px rgba(0,0,0,0.05)',
                    textAlign: 'left'
                }}>
                    {components}
                </div>
            </div>
        )
    }

    const setupStructure = () => {

        // set default active view
        let parameters = QueryString.parse(window.location.search);
        switch(parameters.route) {
            case 'duracode':
            case 'duralink':
            case 'duralink_privacy':
            setActive({ view: parameters.route });
            break;

            default:
            setActive({ view: 'home' });
        }

        // amimate components into view, lol
        setBadgeAnimations({ opacity: 1, transform: 'scale(1)' });
        setContentAnimations({ opacity: 1, top: 0 });
        setLogoAnimations({ left: 0, opacity: 1 });
        setMenuAnimations({ opacity: 1, right: 0 });

        // attempt to run auto login using auth token
        let token = localStorage.getItem('user.refresh_token');
        if(token) {
            onLoginWithRefreshToken(token);
        }
    }

    const utils = {
        alert: {
            show: async props => {
                try {
                    if(loader) {
                        await utils.loader.hide();
                    }
                    setAlerts(alerts => update(alerts, {
                        $push: [{
                            id: `${moment().unix()}-${Math.random()}`,
                            ...props
                        }]
                    }))
                } catch(e) {
                    console.log(e.message)
                }
            },
            dev: async () => {
                try {
                    if(loader) {
                        await utils.loader.hide();
                    }
                    setAlerts(alerts => update(alerts, {
                        $push: [{
                            id: `${moment().unix()}-${Math.random()}`,
                            title: 'In Development',
                            message: 'This feature is currently under development and will become available at a later date'
                        }]
                    }))

                } catch(e) {
                    console.log(e.message)
                }
            },
        },
        animations: {
            get: key => {
                switch(key) {
                    case 'panel':
                    return {
                        animateIn: {
                            top: 0,
                            opacity: 1
                        },
                        animateOut: {
                            top: -50,
                            opacity: 0
                        },
                        config: {
                            friction: 10
                        },
                        delay: 0.5
                    }

                    default:
                    return null;
                }
            }
        },
        api: {
            headers: () => {
                return {
                    'Content-Type': 'application/json',
                    'X-API': `Version ${API.version}`,
                    'X-Timezone': `TZ ${moment.tz.guess()}`,
                    'X-Web': `Build ${API.build}`,
                    ...userRef.current && {
                        'Authorization': `Bearer ${userRef.current.token}`,
                        'Identification': `User ${userRef.current.user_id}`
                    }
                }
            }
        },
        content: ContentManager,
        datePicker: {
            show: props => setDatePicker({
                utils: utils,
                id: `${moment().unix()}-${Math.random()}`,
                ...props
            })
        },
        events: EventsManager,
        layer: {
            open: layer => onOpenLayer(layer),
            close: layer => onCloseLayer(layer),
            requestClose: id => {
                let closeEvent = new CustomEvent('onLayerAction', {
                    detail: {
                        action: 'close',
                        layerID: id
                    }
                });
                window.dispatchEvent(closeEvent);
            }
        },
        loader: {
            show: async () => {
                return new Promise(resolve => {
                    setLoader(true);
                    setTimeout(resolve, 500)
                });
            },
            hide: async () => {
                return new Promise(resolve => {
                    setLoader(false);
                    setTimeout(resolve, 500)
                });
            }
        },
        navigation: {
            set: setActive
        },
        sheet: {
            show: (sheet, callback) => {
                setSheet({ ...sheet, onClick: callback })
            }
        },
        user: {
            set: onLogin
        }
    }

    useEffect(() => {
        window.scrollTo(0,0);
        if(Utils.isMobile() === true) {
            setMenuExpanded(false);
        }
    }, [active]);

    useEffect(() => {
        layersRef.current = layers;
    }, [layers]);

    useEffect(() => {
        if(Utils.isMobile() === false) {
            setMenuDrawerAnimations({ left: size.width });
            return;
        }
        setMenuDrawerAnimations({ left: menuExpanded ? 0 : size.width });
    }, [menuExpanded, size]);

    useEffect(() => {
        if(Utils.isMobile() === true) {
            onSelectedNavigationItemChange();
        }
    }, [selectedNavigationItem]);

    useEffect(() => {
        userRef.current = user;
    }, [user]);

    useEffect(() => {

        setupStructure();

        // setup window listeners
        window.addEventListener('resize', onSizeChange);
        window.addEventListener('beforeunload', evt => {
            evt.returnValue = null;
            return null;
        });

        // css variables
        document.documentElement.style.setProperty('--text', Appearance.colors.text());
        document.documentElement.style.setProperty('--textfield', Appearance.colors.textField());
        document.documentElement.style.setProperty('--soft_border', Appearance.colors.softBorder());

        // theme and theme listeners
        window.theme = 'dark'; //window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light';
        document.documentElement.style.setProperty('--theme', window.theme);
        window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', onUpdateTheme);
        onSetStyleSheetProperties();

    }, []);

    return (
        <div
        className={`root-container ${theme}`}
        nonce={nonce}
        style={{
            height: size.height,
            position: 'relative',
            width: size.width
        }}>
            {getMainContent()}
            {getDesktopVersionDetails()}
            {datePicker && (
                <DatePicker
                {...datePicker}
                onClose={() => {
                    setDatePicker(null);
                    if(typeof(datePicker.onClose) === 'function') {
                        datePicker.onClose();
                    }
                }} />
            )}
            {sheet && (
                <Sheet
                {...sheet}
                onClose={setSheet.bind(this, null)}/>
            )}
            <AlertStack>
                {alerts.map((alert, index) => (
                    <Alert
                    {...alert}
                    key={index}
                    index={(alerts.length - 1) - index}
                    onClose={id => {
                        if(typeof(alert.onClose) === 'function') {
                            alert.onClose();
                        }
                        setAlerts(alerts => {
                            return alerts.filter(alert => {
                                return id !== alert.id;
                            });
                        })
                    }} 
                    utils={utils} />
                ))}
            </AlertStack>
        </div>
    )
}

export default App;
