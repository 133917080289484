import API from 'files/api.js';

const buildRequest = (url, props) => {
    let newURL = `${url}?type=${props.type}`;
    for(var i in props) {
        if(i == 'type') {
            continue;
        }
        newURL += `&${i}=${typeof(props[i]) === 'object' ? JSON.stringify(props[i]) : encodeURIComponent(props[i])}`;
    }
    return newURL;
}

const del = async (utils, url, props, server) => {
    return new Promise(async (resolve, reject) => {
        try {

            let response = await fetch(server ? `${server}${url}` : `/api/v${API.version}${url}`, {
                body: JSON.stringify(props),
                headers: utils.api.headers(),
                method: 'DELETE',
                withCredentials: true
            });

            // determine if response code represents a 5xx level error
            if([502, 503, 504].includes(response.status)) {
                throw new Error('Please try again in a few minutes');
            }

            // prevent moving forward if an error was thrown
            if(response.status !== 200) {
                let text = await response.text();
                throw new Error(text || 'An unknown error occurred');
            }

            let json = await response.json();
            resolve(json);

        } catch(e) {
            reject(e);
        }
    });
}

const get = async (utils, url, props) => {
    return new Promise(async (resolve, reject) => {
        try {

            let response = await fetch(buildRequest(`/api/v${API.version}${url}`, props), {
                headers: utils.api.headers(),
                method: 'GET',
                withCredentials: true
            });

            // determine if response code represents a 5xx level error
            if([502, 503, 504].includes(response.status)) {
                throw new Error('Please try again in a few minutes');
            }

            // prevent moving forward if an error was thrown
            if(response.status !== 200) {
                let text = await response.text();
                throw new Error(text || 'An unknown error occurred');
            }

            let json = await response.json();
            resolve(json);

        } catch(e) {
            reject(e);
        }
    });
}

const post = async (utils, url, props) => {
    return new Promise(async (resolve, reject) => {
        try {

            let response = await fetch(`/api/v${API.version}${url}`, {
                method: 'POST',
                withCredentials: true,
                headers: utils.api.headers(),
                body: JSON.stringify(props)
            });

            // determine if response code represents a 5xx level error
            if([502, 503, 504].includes(response.status)) {
                throw new Error('Please try again in a few minutes');
            }

            // prevent moving forward if an error was thrown
            if(response.status !== 200) {
                let text = await response.text();
                throw new Error(text || 'An unknown error occurred');
            }

            let json = await response.json();
            resolve(json);

        } catch(e) {
            reject(e);
        }
    });
}

const put = async (utils, url, props) => {
    return new Promise(async (resolve, reject) => {
        try {

            let response = await fetch(`/api/v${API.version}${url}`, {
                body: JSON.stringify(props),
                headers: utils.api.headers(),
                method: 'PUT',
                withCredentials: true
            });

            // determine if response code represents a 5xx level error
            if([502, 503, 504].includes(response.status)) {
                throw new Error('Please try again in a few minutes');
            }

            // prevent moving forward if an error was thrown
            if(response.status !== 200) {
                let text = await response.text();
                throw new Error(text || 'An unknown error occurred');
            }

            let json = await response.json();
            resolve(json);

        } catch(e) {
            console.error(e.message);
            reject(e);
        }
    });
}

const upload = async (utils, url, props, options = {}) => {
    return new Promise(async (resolve, reject) => {
        try {

            // declare request url
            let request = new URL(`${API.server}/api/v${API.version}${url}`);

            // build url and xml request
            let xhr = new XMLHttpRequest();
            xhr.open('POST', request);

            // set authentication headers for request
            let headers = utils.api.headers();
            Object.keys(headers).filter(key => key !== 'Content-Type').forEach(key => xhr.setRequestHeader(key, headers[key]));

            // prepare form components for request
            let data = new FormData();
            Object.keys(props).forEach(key => {
                if(typeof(props[key]) !== 'object') {
                    return data.append(key, props[key]);
                }
                return data.append(key, JSON.stringify(props[key]));
            });

            // add file data to form data object
            if(options.files) {
                options.files.forEach(file => data.append(file.key, file.data));
            }

            // send request to server declare event handlers for response
            xhr.send(data);
            xhr.onerror = () => {
                let error = new Error(xhr.responseText);
                reject(error);
            }
            xhr.onprogress = evt => {
                console.log(evt);
                options.onProgressChange({
                    current: evt.loaded,
                    percent: (evt.loaded / evt.total) * 100,
                    total: evt.total || 0
                });
            }
            xhr.onload = () => {

                // prevent moving forward if any other status than success is returned
                if(xhr.status !== 200) {
                    let error = new Error(xhr.responseText || 'An unknown error occurred');
                    reject(error);
                    return;
                }

                // check for conflicts and allow user to respond
                if(xhr.status === 409) {
                    let error = new Error();
                    error.code = xhr.status;
                    error.category = xhr.response.category;
                    error.message = xhr.response.message;
                    error.response = xhr.response;
                    reject(error);
                    return;
                }

                // return json response
                let json = JSON.parse(xhr.response);
                resolve(json);
            }

        } catch(e) {
            reject(e);
        }
    })
}

const Request = {
    delete: del,
    get: get,
    post: post,
    put: put,
    upload: upload
}
export default Request;
